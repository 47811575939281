import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Badge, Box, IconButton, Link, Popover as MuiPopover, Tooltip } from '@mui/material';
import { CrmNotification } from '../../custom/types/crm/notification';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabPanel as MuiTabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { PhoneOutlined } from '@mui/icons-material';
import CallActivityPopoverHeader from './callActivity/CallActivityPopoverHeader';
import CallActivity from '../../custom/pages/CallActivity';
import { CallType } from '../../custom/types/crm/callActivity';
import { NavLink } from 'react-router-dom';
import Contacts from '../../custom/pages/contacts/Contacts';

const StyledPopover = styled(MuiPopover)(({ theme }) => ({
  '& > .MuiPaper-root': {
    width: '450px',
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const TabPanel = styled(MuiTabPanel)`
  padding: 0;
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

export type NotificationListType = {
  notifications: CrmNotification[] | undefined;
  handleOnNotificationClick: (notification: CrmNotification) => void;
};
const boxStyles = {
  maxWidth: '540px',
  backgroundColor: 'white',
  borderRadius: '5px',
  boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)',
  maxHeight: '85vh',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: 'auto',
};

/**
 *
 */
function NavbarCallActivityDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [tabSelection, setTabSelection] = useState<number>(0);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabSelection(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Call Activity">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={0}>
            <PhoneOutlined />
          </Indicator>
        </IconButton>
      </Tooltip>
      <StyledPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box pt={5} px={4} sx={boxStyles}>
          <CallActivityPopoverHeader />
          <TabContext value={tabSelection.toString()}>
            <Tabs
              value={tabSelection}
              onChange={handleChange}
              scrollButtons="auto"
              sx={{ borderBottom: '1px solid #ECECEE', p: 0 }}
            >
              <Tab label={'All'} />
              <Tab label={'Missed'} />
              <Tab label={'Voicemails'} />
              <Tab label={'Contacts'} />
            </Tabs>
            <TabPanel value="0">
              <CallActivity forceCardView={true} showTableOnly={true} paginationEnabled={false} />
            </TabPanel>
            <TabPanel value="1">
              <CallActivity
                forceCardView={true}
                showTableOnly={true}
                paginationEnabled={false}
                callType={CallType.MISSED_CALL}
              />
            </TabPanel>
            <TabPanel value="2">
              <CallActivity
                forceCardView={true}
                showTableOnly={true}
                paginationEnabled={false}
                callType={CallType.VOICEMAIL}
              />
            </TabPanel>
            <TabPanel value="3">
              <Contacts forceCardView={true} showTableOnly={true} paginationEnabled={false} />
            </TabPanel>
          </TabContext>
          <Box
            sx={{
              position: 'sticky', // Position the footer relative to the container
              bottom: 0,
              left: 0,
              right: 0,
              height: '2rem', // Adjust as needed
              backgroundColor: '#fff',
              borderTop: '1px solid #ECECEE',
              display: 'flex',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {tabSelection === 3 ? (
              <Link component={NavLink} to="/crm/contacts">
                Show all contacts
              </Link>
            ) : (
              <Link component={NavLink} to="/crm/calls/call-activity">
                Show all call logs
              </Link>
            )}
          </Box>
        </Box>
      </StyledPopover>
    </React.Fragment>
  );
}

export default NavbarCallActivityDropdown;
