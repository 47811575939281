import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import { useQuery } from '@apollo/client';
import { IdentityEmailSendersAccounts } from '../../../../types/identity/admin/twilio/sendGrid';
import { GET_SENDGRID_SENDER_ACCOUNTS } from '../../../../../common/graphQL/admin/twilio/queries';
import { setEmailSendersData, setIsDataLoaded } from '../../../../../redux/admin/sendGrid/emailSendersSlice';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import EllipsisText from '../../../../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../../../../components/DateFormatter';
import { Box, Card, CardContent } from '@mui/material';
import Chip from '@mui/material/Chip';
import LetterAvatar from '../../../../components/Avatar';
import NucleusPage from '../../../../components/NucleusPage';
import EmailAccountsListAdvanceSearchBar, {
  EmailAccountsSearchCriteria,
} from '../components/EmailAccountsListAdvanceSearchBar';

const EmailList = () => {
  const dispatch = useAppDispatch();
  const emailSendersData = useAppSelector((state: RootState) => state.emailSenders.data);

  const [searchString, setSearchString] = useState<string>('');
  const [searchCriteria, setSearchCriteria] = useState<EmailAccountsSearchCriteria>(EmailAccountsSearchCriteria.Email);
  const [statusVerifiedChecked, setStatusVerifiedChecked] = useState<boolean>(false);
  const [statusUnverifiedChecked, setStatusUnverifiedChecked] = useState<boolean>(false);

  const { data: emailsData, loading: isLoading } = useQuery<IdentityEmailSendersAccounts>(
    GET_SENDGRID_SENDER_ACCOUNTS,
    {
      variables: {
        filter:
          statusVerifiedChecked || statusUnverifiedChecked
            ? {
                verified:
                  statusVerifiedChecked && statusUnverifiedChecked
                    ? undefined
                    : statusVerifiedChecked
                      ? true
                      : statusUnverifiedChecked
                        ? false
                        : undefined,
              }
            : undefined,
        search: searchString
          ? {
              email: searchCriteria === EmailAccountsSearchCriteria.Email ? searchString : searchString,
            }
          : undefined,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        dispatch(setEmailSendersData([]));
        dispatch(setIsDataLoaded(true));
      },
    },
  );

  useEffect(() => {
    if (emailsData && emailsData?.identity?.admin?.twilio?.sendGridAccounts) {
      const emails = emailsData?.identity?.admin?.twilio?.sendGridAccounts ?? [];
      if (emails.length > 0) {
        dispatch(setEmailSendersData(emails));
        dispatch(setIsDataLoaded(true));
      } else {
        dispatch(setEmailSendersData([]));
        dispatch(setIsDataLoaded(true));
      }
    }
  }, [emailsData, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        if (!params.row.from.name) {
          return 'None';
        }

        const name = params.row.from.name;

        return (
          <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {name && <LetterAvatar name={name.toUpperCase()} sx={{ backgroundColor: 'orange' }} />}
              <Box sx={{ pl: 2, flex: 1, display: 'inline-grid' }}>
                {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
              </Box>
            </Box>
          </React.Fragment>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 250,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.from.email) {
          return 'None';
        }

        return <EllipsisText content={params.row.from.email} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdAt) {
          return 'None';
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: params.row.createdAt,
              dateFormatType: DateFormatType.Standard,
            })}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.verified) {
          return 'None';
        }
        const status = params.row.verified.status;
        return (
          <Chip
            label={status ? 'Verified' : 'Unverified'}
            size={'small'}
            color={status ? 'success' : 'warning'}
            variant="outlined"
          />
        );
      },
    },
  ];

  const searchEmails = (
    searchValue: string,
    selectedField?: EmailAccountsSearchCriteria,
    verified?: boolean,
    unverified?: boolean,
  ) => {
    setSearchString(searchValue);
    setSearchCriteria(selectedField ?? EmailAccountsSearchCriteria.Email);
    setStatusVerifiedChecked(verified ?? false);
    setStatusUnverifiedChecked(unverified ?? false);
  };

  return (
    <NucleusPage
      title="Email Accounts"
      header={`Email Accounts`}
      content={
        <React.Fragment>
          <EmailAccountsListAdvanceSearchBar searchData={searchEmails} options={{ hideCriteria: true }} />
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <DataGrid
                  loading={isLoading}
                  rows={emailSendersData}
                  disableRowSelectionOnClick
                  columns={columns}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Box>
            </CardContent>
          </Card>
        </React.Fragment>
      }
    />
  );
};

export default EmailList;
