import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { Badge, Grid, Typography } from '@mui/material';

import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import AvatarImage from '../../custom/components/AvatarImage';

const Footer = styled.div`
  background-color: ${props => props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)} ${props => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)};
  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

/**
 *
 * @param root0
 */
const SidebarFooter: React.FC = ({ ...rest }) => {
  const currentUser = useContext(CurrentUserContext);

  if (!currentUser) return <></>;
  const letterAvatarName =
    currentUser && currentUser.firstName && currentUser.lastName
      ? `${currentUser.firstName.replace(/\s+/g, '').trim()} ${currentUser.lastName.trim()}`
      : '';

  return (
    <Footer {...rest}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <AvatarImage img={currentUser.profilePictureUrl} size={40} letterAvatarName={letterAvatarName} />
          </FooterBadge>
        </Grid>
        <Grid item>
          <FooterText variant="body2">{`${currentUser?.firstName} ${currentUser?.lastName}`}</FooterText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
