import { gql } from '@apollo/client';

export const USER_CALL_ACTIVITIES = gql`
  query UserCallActivities($filter: CrmUserCallActivitiesInput, $pagination: PaginationInput) {
    crm {
      userOptions {
        userCallActivities(filter: $filter, pagination: $pagination) {
          totalCount
          callActivities {
            callSid
            status
            mediaUrl
            duration
            callType
            receiver
            createdDate
            caller
            callerData {
              ... on CrmLead {
                contactNo
                name
                __typename
                id
                email
                address {
                  id
                  streetNo
                  streetName
                  city
                  state
                  zipCode
                  country
                  lat
                  lng
                  createdAt
                }
                assignedTo {
                  firstName
                  lastName
                }
                leadForm {
                  platform
                }
                interestType {
                  interestTypeName
                }
              }
              ... on CrmUser {
                firstName
                lastName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
            receiverData {
              ... on CrmLead {
                name
                contactNo
                __typename
              }
              ... on CrmUser {
                lastName
                firstName
                twilio {
                  projectedAddress
                }
                __typename
              }
            }
          }
        }
      }
    }
  }
`;
