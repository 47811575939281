import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmLead, CrmLeadStatus } from '../custom/types/crm/lead';

interface LeadsState {
  data: CrmLead[];
  isDataLoaded: boolean;
  totalLeads: number;
}

const initialState: LeadsState = {
  data: [],
  isDataLoaded: false,
  totalLeads: 0,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setLeadsData(state, action: PayloadAction<CrmLead[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalLeads(state, action: PayloadAction<number>) {
      state.totalLeads = action.payload;
    },
    updateLeadStatus(state, action: PayloadAction<{ leadId: string; status: string }>) {
      const { leadId, status } = action.payload;
      // @ts-ignore
      const lead = state.data.find(leadInfo => leadInfo.id === leadId);
      if (lead) {
        lead.status = status as CrmLeadStatus;
      }
    },
  },
});

export const { setLeadsData, setIsDataLoaded, setTotalLeads, updateLeadStatus } = contactsSlice.actions;
