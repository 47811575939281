import React from 'react';
import { Grid, Typography } from '@mui/material';

const CallActivityPopoverHeader: React.FC = () => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" pb={3}>
      <Grid item>
        <Typography variant="h3">Call Activity</Typography>
      </Grid>
    </Grid>
  );
};

export default CallActivityPopoverHeader;
