import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';

import { IconButton as MuiIconButton, Menu, MenuItem, Tooltip } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../custom/contexts/TwilioContext';
import AvatarImage from '../../custom/components/AvatarImage';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

/**
 *
 */
function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const currentUser = useContext(CurrentUserContext);
  const letterAvatarName =
    currentUser && currentUser.firstName && currentUser.lastName
      ? `${currentUser.firstName.replace(/\s+/g, '').trim()} ${currentUser.lastName.trim()}`
      : '';

  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { setVoiceTokenContext, setDeviceContext, deviceContext } = React.useContext(
    VoiceTokenContext,
  ) as VoiceTokenContextProps;

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    if (signOut) {
      sessionStorage.clear();
      setVoiceTokenContext('');
      setDeviceContext(deviceContext?.destroy()!);
      signOut();
      navigate('/');
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <AvatarImage
            img={currentUser?.profilePictureUrl}
            edit={false}
            size={30}
            fontSize={'14px'}
            letterAvatarName={letterAvatarName}
          />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={closeMenu} component={Link} to={'/crm/user-profile'}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
