import { gql } from '@apollo/client';

export const CREATE_DESIGN = gql`
  mutation CreateDesign($input: CreateDesignInput!) {
    projectManagement {
      project {
        createDesign(input: $input) {
          success
          design {
            id
            name
            asset {
              tenantId
              userId
              projectId
              designId
              url
              fileName
              takenAt
              assetType
            }
            summary {
              designId
              designRequestId
              externalProviderId
              projectId
              createdAt
              systemSizeAc
              systemSizePtc
              systemSizeStc
              billOfMaterials {
                id
                componentType
                sku
                name
                manufacturerName
                quantity
              }
              energyProduction {
                upToDate
                annual
                annualOffset
              }
            }
            asset {
              assetType
              designId
              fileName
              projectId
              url
            }
          }
        }
      }
    }
  }
`;
