import { CrmDisposition } from '../disposition';
import { CrmTaskScaffoldType } from '../taskScaffoldType';
import { CrmUser } from '../user';
import { CrmLead } from '../lead';
import { CrmTaskType } from '../taskType';
import {
  AppointmentTaskHistoryType,
  ConversationTaskHistoryType,
  CreatedTaskHistoryType,
  SentEmailTaskHistoryType,
} from '../../../pages/task/taskTypes';
import { IdentityNote } from '../../identity/user/note';

export type CrmTaskSchedules = {
  crm: {
    taskSchedules: CrmTaskSchedule[];
  };
};

export type CrmTaskSchedulesPaged = {
  crm: {
    taskSchedulesPaged: {
      totalCount: number;
      taskSchedules: CrmTaskSchedule[];
    };
  };
};

export enum TaskScheduleStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type CrmTaskSchedule = {
  id: number;
  taskTitle: string;
  taskTypeId: number;
  taskType: CrmTaskType;
  leadId: number;
  lead: CrmLead;
  dueDate: string;
  startTime: string;
  endTime: string;
  createdById: string;
  createdBy: CrmUser;
  assignedToId: string;
  assignedTo: CrmUser;
  dispositionId: number;
  disposition: CrmDisposition;
  createdAt: string;
  taskScaffoldTypeId: number;
  taskScaffoldType: CrmTaskScaffoldType;
  executionSchedule: string;
  isAutomated: boolean;
  remarks: string;
  history: TaskScheduleHistoryType[];
  status: TaskScheduleStatusEnum;
  notes: IdentityNote[];
};

export type CrmTaskScheduleMutationResponse = {
  crm: {
    taskSchedule: {
      updateTaskSchedule: CrmTaskScheduleMutationResult;
    };
  };
};

export type CrmTaskScheduleMutationResult = {
  success: boolean;
  result: CrmTaskSchedule;
};

export type TaskScheduleStatus = {
  status: TaskScheduleStatusEnum;
};

export type TaskScheduleHistoryType = {
  id: number;
  eventSubject: EventSubjectType;
  eventType: string;
  eventContextId: string;
  identity: string;
  timestamp: string;
  details:
    | CreatedTaskHistoryType
    | AppointmentTaskHistoryType
    | SentEmailTaskHistoryType
    | ConversationTaskHistoryType
    | IdentityNote;
  summary: string;
};

export enum EventSubjectType {
  TWILIO_CONVERSATION = 'TWILIO_CONVERSATION',
  EMAIL = 'EMAIL',
  APPOINTMENT = 'APPOINTMENT',
  IDENTITY_NOTE = 'IDENTITY_NOTE',
  TASKSCHEDULE = 'TASKSCHEDULE',
  CALLPAYLOAD = 'CALLPAYLOAD',
}
