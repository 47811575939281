import React, { useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Cropper from 'react-easy-crop';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slider } from '@mui/material';
import { cropImage } from './cropUtils';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PROFILE } from '../queries/identity/user';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import LetterAvatar, { ProfileAvatar } from './Avatar';
import { GET_USER } from '../../common/graphQL';
import { PhotoCamera } from '@mui/icons-material';

interface ImageCropperProps {
  open: boolean;
  image: string | undefined;
  onComplete: (imagePromise: Promise<string>) => void;
  containerStyle?: React.CSSProperties;
  onClose: () => void;
  [key: string]: any;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ open, image, onClose, onComplete, containerStyle, ...props }) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onZoomChange = (newZoomNumber: number) => {
    setZoom(newZoomNumber);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Crop Image</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{ position: 'absolute', right: 8, top: 8, color: '#888' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape={'round'}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPx) => {
              setCroppedAreaPixels(croppedAreaPx);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
      </DialogContent>
      <Box
        style={{
          position: 'absolute',
          width: '50%',
          bottom: 0,
          left: '50%',
          height: '80px',
          display: 'flex',
          alignItems: 'center',
          transform: 'translateX(-50%)',
        }}
      >
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_e, newZoom) => onZoomChange(newZoom as number)}
        />
      </Box>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (image && croppedAreaPixels) {
              const result = cropImage(image, croppedAreaPixels);
              onComplete(
                result.then(cropped => {
                  if (cropped) {
                    return cropped; // Ensures a string is passed
                  } else {
                    throw new Error('Failed to crop the image');
                  }
                }),
              );
            }
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AvatarImage: React.FC<{
  letterAvatarName: string;
  fontSize?: string;
  size?: number;
  img?: string;
  edit?: boolean;
}> = ({ img, letterAvatarName, edit = false, size = 60, fontSize = '25px' }) => {
  const [image, setImage] = useState<ImageListType>([]);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false); // Track hover state
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [runUploadImage] = useMutation(UPDATE_USER_PROFILE, {
    refetchQueries: [{ query: GET_USER }],
  });

  return (
    <React.Fragment>
      <ImageUploading
        value={image}
        onChange={newImage => {
          if (newImage.length > 0) {
            setImage(newImage);
            setDialogOpen(true); // Open the crop dialog after selecting a file
          }
        }}
      >
        {({ onImageUpload }) => (
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {/* Avatar display */}
            {croppedImage ? (
              <ProfileAvatar src={croppedImage} size={size} />
            ) : img ? (
              <ProfileAvatar src={img} size={size} />
            ) : (
              <LetterAvatar
                {...{
                  name: letterAvatarName,
                  sx: { backgroundColor: 'blue', width: size, height: size, fontSize: fontSize },
                }}
              />
            )}

            {/* Hover effect: Edit icon */}
            {isHovered && edit && (
              <IconButton
                style={{
                  position: 'absolute',
                  bottom: '0%',
                  right: '0%',
                  margin: '-7px',
                  backgroundColor: '#2196f3',
                  color: 'white',
                  zIndex: 2,
                }}
                onClick={onImageUpload} // Trigger file input on click
              >
                <PhotoCamera style={{ width: '1rem', height: '1rem' }} />
              </IconButton>
            )}
          </div>
        )}
      </ImageUploading>
      <ImageCropper
        open={dialogOpen}
        image={image.length > 0 ? image[0].dataURL : undefined}
        onComplete={async imagePromise => {
          try {
            const croppedImg = await imagePromise;
            setCroppedImage(croppedImg);
            setDialogOpen(false);

            // Run the mutation with the cropped image
            runUploadImage({
              variables: {
                input: {
                  picture: croppedImg, // Adjust the variable name based on your GraphQL query
                },
              },
            });
          } catch (error) {
            enqueueSnackbar('An error occurred while creating the contract', {
              variant: 'error',
              persist: true,
              action: key => (
                <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
                  <CloseIcon color="disabled" />
                </IconButton>
              ),
            });
          }
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: 300,
          background: '#333',
        }}
        onClose={() => setDialogOpen(false)} // Handle dialog close
      />
    </React.Fragment>
  );
};

export default AvatarImage;
