import { Button, Grid } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { VoiceCallComponent } from './VoiceCallComponent';
import { IdentityUsers } from '../../../types/crm/user';
import { CrmTaskType, TaskTypeNameType } from '../../../types/crm/taskType';

export const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const Header = styled(Grid)({
  height: '4rem', // Set a fixed height for the footer
  padding: '0.5rem',
  // background: '#e1e1e1', // Just for better visibility
});

export type ObjectProps = {
  contactNumber?: string;
  userMakingCall?: IdentityUsers;
};

export interface TaskModalHeaderProps {
  taskType: CrmTaskType;
  objProps: ObjectProps;
}

export const TaskModalHeader = (props: TaskModalHeaderProps) => {
  const { taskType, objProps } = props;

  const TaskHeader = () => {
    switch (taskType.taskTypeName) {
      case TaskTypeNameType.VOICE_CALL:
        if (objProps.userMakingCall?.identity) {
          return (
            <VoiceCallComponent
              contactNumber={objProps.contactNumber!}
              userMakingCall={objProps.userMakingCall!.identity?.users[0]}
            />
          );
        }
        return undefined;
      default:
        return null;
    }
  };

  return (
    <Header container alignItems={'center'} justifyContent={'space-evenly'}>
      <TaskHeader />
    </Header>
  );
};
