import { CrmLead } from './lead';
import { CrmUser } from './user';

export type CrmUserCallActivities = {
  crm: {
    userOptions: {
      userCallActivities: {
        totalCount: number;
        callActivities: CrmUserCallActivity[];
      };
    };
  };
};

export enum CallType {
  INCOMING_CALL = 'INCOMING_CALL',
  OUTGOING_CALL = 'OUTGOING_CALL',
  MISSED_CALL = 'MISSED_CALL',
  VOICEMAIL = 'VOICEMAIL',
}

export type CrmUserCallActivity = {
  callSid: string;
  status: string;
  mediaUrl: string;
  duration: number;
  callType: CallType;
  receiver: string;
  caller: string;
  receiverData: CrmUser | CrmLead;
  callerData: CrmUser | CrmLead;
  createdDate: string;
};

export const getCallerName = (callerData: CrmUser | CrmLead): string | null => {
  if (callerData) {
    let callerName = '';
    if (callerData.__typename === 'CrmUser') {
      const crmUser = callerData as CrmUser;
      callerName = `${crmUser?.firstName ?? ''} ${crmUser?.lastName ?? ''}`;
    } else {
      const crmLead = callerData as CrmLead;
      callerName = `${crmLead?.name ?? ''}`;
    }

    return callerName;
  }

  return null;
};
