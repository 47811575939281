import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

type searchBarProps = {
  searchData: (searchValue: string, fields: string[]) => void;
  placeholder?: string;
  showFieldOptions?: boolean;
};

const SearchBar = (props: searchBarProps) => {
  const { searchData, placeholder = 'Search', showFieldOptions = true } = props;
  const [searchValue, setSearchValue] = useState('');
  const [selectedFields, setSelectedFields] = useState<string[]>(['name']);

  useEffect(() => {
    setSearchValue('');
  }, [placeholder]);

  /**
   * Handles the input change event and updates the searched value.
   * @param {React.ChangeEvent<HTMLInputElement>} event The input change event.
   * @returns {void} returns void, it only updates the searchValue variable
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  /**
   * Used to handle the receive or handle the searched data/value coming from the searchBar component
   * @param {string} params The searched value
   * @returns {void} returns void, this act as an intermediary or a bridge,
   * it calls the searchData call back function and passing a data
   */
  const handleSearchData = () => {
    searchData(searchValue, selectedFields);
  };

  const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchData();
    }
  };

  const fieldOptions = [
    { type: 'name', displayName: 'Name', shortName: 'Name' },
    { type: 'email', displayName: 'Email Address', shortName: 'Email' },
    { type: 'assignee', displayName: 'Assignee', shortName: 'Assignee' },
    { type: 'contactNo', displayName: 'Phone Number', shortName: 'Phone' },
  ];

  const handleFieldChange = (event: any) => {
    const value = event.target.value;
    setSelectedFields(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClear = () => {
    setSearchValue('');
    searchData('', selectedFields);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden',
            height: '3rem',
          }}
        >
          <TextField
            placeholder={placeholder}
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            variant="standard"
            style={{ flex: 1 }}
          />
          {searchValue && (
            <React.Fragment>
              <Button onClick={handleClear}>Clear</Button>
            </React.Fragment>
          )}
          <Button onClick={handleSearchData}>Search</Button>
          {showFieldOptions && (
            <FormControl variant="outlined" style={{ minWidth: '200px', margin: 0, marginLeft: 10 }}>
              <Select
                multiple
                value={selectedFields}
                onChange={handleFieldChange}
                input={<Input disableUnderline />}
                displayEmpty
                renderValue={selected => {
                  if (selected.length === 0) {
                    return <em>Select Filter</em>;
                  }
                  if (selected.length === 4) {
                    return <span>Search All</span>;
                  }

                  // Use shortName for the selected values
                  return selected.map(sel => fieldOptions.find(option => option.type === sel)?.shortName).join(', ');
                }}
                variant="standard"
              >
                {fieldOptions.map(field => (
                  <MenuItem key={field.type} value={field.type}>
                    <Checkbox checked={selectedFields.indexOf(field.type) > -1} />
                    <ListItemText primary={field.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </FormControl>
    </div>
  );
};

export default SearchBar;
