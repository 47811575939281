import React from 'react';
import { useQuery } from '@apollo/client';
import { CrmUser, IdentityUsers } from '../types/crm/user';
import { GET_USER } from '../../common/graphQL';
import { AuthContext } from '../../contexts/CognitoContext';
import { NotificationType } from '../types/conversation';
import { IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

export type UserIdentityContextProps = {
  userIdentity: CrmUser | undefined;
  setUserIdentity: (userIdentity: CrmUser) => void;
};

export const UserIdentityContext = React.createContext<UserIdentityContextProps | undefined>(undefined);

export const UserIdentityProvider = ({ children }: { children: React.ReactNode }) => {
  const [userIdentity, setUserIdentity] = React.useState<CrmUser>({} as CrmUser);
  const { user } = React.useContext(AuthContext) || {};
  const userId = user && user['custom:nucleus-id'];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleNotification = ({ message, variant }: NotificationType) => {
    enqueueSnackbar(message, {
      variant: variant,
      persist: true,
      action: key => (
        <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
          <GridCloseIcon color="disabled" />
        </IconButton>
      ),
    });
  };

  const { data: identity } = useQuery<IdentityUsers>(GET_USER, {
    variables: {
      filter: {
        userId: userId!,
      },
    },
    onCompleted: result => {
      if (result) {
        const userDetails = identity?.identity?.users?.[0];

        let updatedUserDetails: CrmUser = {} as CrmUser;

        // If user has Twilio projected address, add Twilio permissions
        const hasPhoneNumber = identity?.identity?.users[0]?.twilio.projectedAddress;
        // Check if user has SendGrid verified email
        const isSendGridVerified = identity?.identity?.users[0]?.twilio?.sendGridVerified ?? false;

        // This is a temporary solution until we have a better way to handle permissions in Hub
        if (userDetails?.twilio?.projectedAddress) {
          updatedUserDetails = {
            ...userDetails,
            permissions: [
              ...(userDetails.permissions || []),
              ...(hasPhoneNumber // Add Twilio permissions if user has phone number
                ? [
                    { name: 'feature:twilio:messaging' },
                    { name: 'feature:twilio:voice:outbound' },
                    { name: 'feature:twilio:voice:inbound' },
                  ]
                : []),
              ...(isSendGridVerified // Add SendGrid permissions if user has phone number
                ? [{ name: 'feature:twilio:sendgrid:email' }]
                : []),
            ],
          };
        }

        setUserIdentity(updatedUserDetails as CrmUser);
      }
    },
    onError() {
      handleNotification({ message: 'An error occurred while retrieving the user identity.', variant: 'error' });
    },
  });

  return (
    <UserIdentityContext.Provider value={{ userIdentity, setUserIdentity }}>{children}</UserIdentityContext.Provider>
  );
};
