import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query Users {
    identity {
      users {
        id
        email
        firstName
        lastName
        twilio {
          sendGridVerified
          projectedAddress
          sendGridId
        }
        roles {
          id
          name
        }
        connectedServices {
          service
          status
          info
        }
      }
    }
  }
`;

export const ADMIN_GET_PAGED_USERS = gql`
  query UsersPaged($pagination: PaginationInput, $filter: IdentityUserPagedFilter) {
    identity {
      usersPaged(pagination: $pagination, filter: $filter) {
        totalCount
        users {
          id
          email
          firstName
          lastName
          profilePictureUrl
          twilio {
            sendGridVerified
            sendGridId
            projectedAddress
          }
          roles {
            id
            name
          }
          connectedServices {
            service
            status
            info
          }
        }
      }
    }
  }
`;

export const ADMIN_GET_USERS_COUNT = gql`
  query UsersTotalCount {
    identity {
      usersPaged {
        totalCount
      }
    }
  }
`;

export const GET_USER_ACTIVITY = gql`
  query ActivityTimeline {
    crm {
      userOptions {
        activityTimeline {
          activityType
          date
          data {
            ... on CrmAppointment {
              lead {
                name
                contactNo
                email
                setter {
                  firstName
                  lastName
                }
                address {
                  streetNo
                  streetName
                  city
                  state
                  zipCode
                }
              }
              appointmentCreatedBy: createdBy {
                firstName
                lastName
              }
            }
            ... on IdentityNote {
              contextId
              content
              mentions {
                mentionId
                mentionType
                mentionContext {
                  ... on IdentityUser {
                    firstName
                    lastName
                    email
                    twilio {
                      projectedAddress
                    }
                  }
                  ... on CrmLead {
                    name
                    contactNo
                    address {
                      streetNo
                      streetName
                      city
                      state
                    }
                  }
                  ... on ProjectManagementProject {
                    customer {
                      firstName
                      lastName
                      address
                      email
                      phone
                    }
                  }
                }
              }
              createdBy {
                firstName
                lastName
              }
            }
            ... on CrmRecording {
              dialer
              mediaUrl
              callerData {
                ... on CrmLead {
                  name
                }
                ... on CrmUser {
                  firstName
                  lastName
                }
              }
            }
            ... on CrmCallPayload {
              caller
              callerData {
                ... on CrmLead {
                  contactNo
                  name
                  __typename
                  id
                  email
                  address {
                    id
                    streetNo
                    streetName
                    city
                    state
                    zipCode
                    country
                    lat
                    lng
                    createdAt
                  }
                  assignedTo {
                    firstName
                    lastName
                  }
                  leadForm {
                    platform
                  }
                  interestType {
                    interestTypeName
                  }
                }
                ... on CrmUser {
                  firstName
                  lastName
                  twilio {
                    projectedAddress
                  }
                  __typename
                }
              }
            }
            ... on CrmTwilioConversationMessage {
              body
              attributes
            }
          }
          title
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query IdentityUsersFilter($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        id
        email
        firstName
        lastName
        profilePictureUrl
        twilio {
          projectedAddress
          sendGridVerified
          sendGridId
          voiceMessages {
            service
            url
            publicId
            messageType
          }
        }
        permissions {
          name
        }
        contactDetails {
          id
          email
          companyName
          tenantId
        }
        tenantId
        roles {
          name
        }
      }
    }
  }
`;

export const APPOINTMENT_TASK_FORM_GET_USER = gql`
  query AppointmentTaskFormGetUsers($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_INTEREST_TYPES_NEW_LEADS = gql`
  query InterestTypesNewLeads {
    crm {
      preferences {
        interestTypes {
          id
          interestTypeName
          createdAt
        }
      }
    }
  }
`;

export const GET_ROOF_TYPES_NEW_LEADS = gql`
  query RoofTypesNewLeads {
    crm {
      preferences {
        roofTypes {
          id
          roofTypeName
          createdAt
        }
      }
    }
  }
`;

export const GET_USERS_MENTION = gql`
  query GET_USERS_MENTION($filter: IdentityUserPagedFilter) {
    identity {
      usersPaged(filter: $filter) {
        email
        firstName
        id
        lastName
      }
    }
  }
`;

export const GET_PAGED_USERS = gql`
  query GET_PAGED_USERS($filter: IdentityUserPagedFilter) {
    identity {
      usersPaged(filter: $filter) {
        totalCount
        users {
          email
          firstName
          id
          lastName
        }
      }
    }
  }
`;

export const GET_USER_VOICE_MESSAGES = gql`
  query GetUserVoiceMessages($filter: IdentityUserFilter) {
    identity {
      users(filter: $filter) {
        twilio {
          voiceMessages {
            service
            url
            publicId
            signature
            resourceType
            etag
            createdAt
            messageType
          }
        }
      }
    }
  }
`;

export const GET_TENANT_DETAILS = gql`
  query Tenant {
    identity {
      admin {
        tenant {
          name
          city
          state
          zip
          phone
          registrationNumber
          companyIdentifier
          fax
          address
        }
      }
    }
  }
`;

export const GET_USER_ACTIVITY_TIMELINE = gql`
  query Crm {
    crm {
      userOptions {
        activityTimeline {
          activityType
          date
          title
          content
          data {
            ... on CrmAppointment {
              lead {
                name
              }
            }
            ... on IdentityNote {
              contextId
              mentions {
                mentionType
                mentionContext {
                  ... on IdentityUser {
                    firstName
                    lastName
                  }
                  ... on CrmLead {
                    name
                  }
                  ... on ProjectManagementProject {
                    customer {
                      firstName
                      lastName
                      address
                    }
                  }
                }
              }
            }
            ... on CrmRecording {
              dialer
              mediaUrl
              callerData {
                ... on CrmLead {
                  name
                }
                ... on CrmUser {
                  firstName
                  lastName
                }
              }
              receiverData {
                ... on CrmUser {
                  firstName
                  lastName
                }
                ... on CrmLead {
                  name
                }
              }
            }
            ... on CrmCallPayload {
              callerData {
                ... on CrmLead {
                  name
                }
                ... on CrmUser {
                  firstName
                  lastName
                }
              }
              receiverData {
                ... on CrmUser {
                  firstName
                  lastName
                }
                ... on CrmLead {
                  name
                }
              }
              recordingUrl
            }
          }
        }
      }
    }
  }
`;
