import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { AppBar as MuiAppBar, Grid, IconButton as MuiIconButton, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import NavbarUserDropdown from './NavbarUserDropdown';
import { useLazyQuery } from '@apollo/client';
import { CrmNotification, CrmNotifications } from '../../custom/types/crm/notification';
import { SnackbarProvider } from 'notistack';
import { usePersistedState } from '../../custom/utils/persistedState';
import { useLocation } from 'react-router-dom';
import NavbarNotificationsDropdown from './NavbarNotificationsDropdown';
import { GET_NOTIFICATIONS } from '../../common/graphQL';
import NavbarSearch from './NavbarSearch';
import NavbarCallActivityDropdown from './NavbarCallActivityDropdown';

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const [state, setState] = usePersistedState('notifications', { minutes: 1 }, { data: [] as CrmNotification[] });

  const location = useLocation();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [getNotifications, { loading }] = useLazyQuery<CrmNotifications>(GET_NOTIFICATIONS, {
    variables: {
      filter: {
        retrieveReadNotifications: true,
        lastNDays: 30,
      },
    },
  });

  useEffect(() => {
    if (!loading) {
      getNotifications().then(result => {
        const notifications = result?.data?.crm?.userOptions?.notifications ?? [];
        setState({ data: notifications });
        if (notifications.length > 0) {
          setState({ data: notifications });
        }
        setIsDataLoaded(true);
      });
    }
  }, [location]); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <SnackbarProvider maxSnack={3} hideIconVariant>
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle} size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} sm={6}>
                <NavbarSearch />
              </Grid>
              <Grid item xs />
              <Grid item>
                <NavbarCallActivityDropdown />
                <NavbarNotificationsDropdown notifications={state?.data} isDataLoaded={isDataLoaded} />
                <NavbarUserDropdown />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
