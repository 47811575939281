import React, { useContext, useEffect } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import NucleusPage from '../components/NucleusPage';
import EllipsisText from '../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../components/DateFormatter';
import { Box, Card, CardMedia, Skeleton, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { CallPayloadStatusEnum } from '../types/crm/callPayload';
import LetterAvatar from '../components/Avatar';
import { ContactDetails } from './contacts/profile/ContactDetails';
import CallStatusIcon, { callTypeColors } from './callActivity/CallActivityCallStatusIcon';
import { USER_CALL_ACTIVITIES } from '../../common/graphQL/call/callActivity/queries';
import { CallType, CrmUserCallActivities, CrmUserCallActivity, getCallerName } from '../types/crm/callActivity';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCallActivityData, setIsDataLoaded, setTotalCalls } from '../../redux/callActivitySlice';
import { CrmLead } from '../types/crm/lead';
import NucleusTable, { DataRow, NucleusTableColumn } from '../components/NucleusTable';
import { formatDuration } from '../../helpers/durationFormatter';

function CallActivity(props: {
  showTableOnly?: boolean;
  forceCardView?: boolean;
  paginationEnabled?: boolean;
  callType?: CallType;
}) {
  const { forceCardView, callType, showTableOnly, paginationEnabled = true } = props;
  const dispatch = useAppDispatch();
  const currentUser = useContext(CurrentUserContext);
  const callActivityData = useAppSelector<CrmUserCallActivity[]>((state: RootState) => state.callActivity.data);
  const callActivityCount = useAppSelector<number>((state: RootState) => state.callActivity.totalCalls);
  const isDataLoaded = useAppSelector<boolean>((state: RootState) => state.callActivity.isDataLoaded);
  const [filteredCallActivityData, setFilteredCallActivityData] = React.useState<CrmUserCallActivity[]>([]);

  const [getCallActivity, { data: callActivityResults }] = useLazyQuery<CrmUserCallActivities>(USER_CALL_ACTIVITIES, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!isDataLoaded) {
      getCallActivity({
        variables: {
          filter: {
            status: [
              CallPayloadStatusEnum.BUSY,
              CallPayloadStatusEnum.CANCELED,
              CallPayloadStatusEnum.NO_ANSWER,
              CallPayloadStatusEnum.FAILED,
              CallPayloadStatusEnum.COMPLETED,
            ],
          },
          pagination: {
            offset: 0,
            limit: 100,
          },
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filteredCallActivity = callType
      ? callActivityData.filter(activity => activity.callType === callType)
      : callActivityData;

    setFilteredCallActivityData(filteredCallActivity);
  }, [callType, callActivityData]);

  useEffect(() => {
    if (callActivityResults && callActivityResults?.crm?.userOptions?.userCallActivities?.callActivities) {
      dispatch(setCallActivityData(callActivityResults?.crm?.userOptions?.userCallActivities?.callActivities));
      dispatch(setIsDataLoaded(true));
      dispatch(setTotalCalls(callActivityResults?.crm?.userOptions?.userCallActivities?.totalCount));
    }
  }, [callActivityResults, dispatch]);

  const cardView = (row: DataRow): React.ReactNode => {
    const data = row as CrmUserCallActivity; // Adjust this depending on the structure of your row
    const name = getCallerName(data.callerData);
    const display = name ?? data.caller ?? 'Unknown';

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column', // Allow vertical stacking for rows
          p: 1.5,
          mb: 1,
          borderLeft: 4,
          borderRadius: 0,
          borderColor: callTypeColors[data.callType],
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              mr: 2,
              p: 1,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CallStatusIcon status={data.callType} />
          </Box>
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                color: data.callType === CallType.MISSED_CALL ? callTypeColors[data.callType] : 'inherit',
              }}
            >
              {display}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              {data.callType
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, char => char.toUpperCase())}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
            <Typography variant="body2" color="text.secondary">
              {DateFormatter({
                date: data.createdDate,
                dateFormatType: DateFormatType.ShortDate,
              })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {DateFormatter({
                date: data.createdDate,
                dateFormatType: DateFormatType.Time,
              })}
            </Typography>
          </Box>
        </Box>
        {data.callType === CallType.VOICEMAIL && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 1,
              height: '2.5rem',
              ml: '2.5rem', // Matches the margin of CallStatusIcon (mr: 2 and p: 1)
            }}
          >
            <CardMedia
              component="audio"
              controls
              src={data.mediaUrl} // Adjust this based on your data structure
              style={{ height: '60%', width: '100%' }}
            />
          </Box>
        )}
      </Card>
    );
  };

  const emptyCardView = (): React.ReactNode => {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          p: 1.5,
          mb: 1,
          borderLeft: 4,
          borderRadius: 0,
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <Box
          sx={{
            mr: 2,
            p: 1,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Skeleton variant="circular" width={32} height={32} />
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <Typography variant="body1">
            <Skeleton width="70%" />
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            <Skeleton width="50%" />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
          <Typography variant="body2" color="text.secondary">
            <Skeleton width="40%" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Skeleton width="30%" />
          </Typography>
        </Box>
      </Card>
    );
  };

  const columns: NucleusTableColumn[] = [
    {
      field: 'callerData',
      headerName: 'Calls',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      width: '20rem',
      renderCell: (value, row) => {
        const contactNo = row.caller ?? '';
        const name = getCallerName(value);
        const rowCallType = row.callType; // Access other fields from the row

        return (
          <React.Fragment>
            <Box sx={{ display: 'flex' }}>
              <CallStatusIcon status={rowCallType} />
              {name ? (
                <React.Fragment>
                  <LetterAvatar
                    name={name ?? ''}
                    sx={{ margin: '0 5px', backgroundColor: 'orange', width: '26px', height: '26px' }}
                  />
                  <EllipsisText content={name} />
                </React.Fragment>
              ) : (
                <EllipsisText content={contactNo || 'Not Provided'} />
              )}
            </Box>
            <Box sx={{ height: '100%' }}>
              {row.mediaUrl && (
                <CardMedia
                  component={'audio'}
                  controls
                  src={row.mediaUrl}
                  style={{ height: '100%', padding: '0.5rem 0 1.5rem 0' }}
                />
              )}
            </Box>
          </React.Fragment>
        );
      },
    },
    {
      field: 'createdDate',
      headerName: 'Date',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      width: '12rem',
      renderCell: value => {
        if (!value) {
          return <EllipsisText content={'--'} />;
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: value,
              dateFormatType: DateFormatType.ShortDateWithTime,
            })}
          />
        );
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      width: '8rem',
      renderCell: value => {
        if (!value) {
          return <EllipsisText content={'--'} />;
        }

        const content = formatDuration(value);

        return <EllipsisText content={content} />;
      },
    },
    {
      field: 'callerData',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      renderCell: value => {
        if (!value) {
          return 'Contact features are unavailable.';
        }

        if (value.__typename === 'CrmUser') {
          return 'Contact features are unavailable.';
        }

        return (
          <ContactDetails
            currentLead={value as CrmLead}
            currentUser={currentUser!}
            onlyShowActions={true}
            compact={true}
          />
        );
      },
    },
  ];

  const CallActivityTable = (): React.ReactNode => {
    return (
      <NucleusTable
        data={filteredCallActivityData}
        totalCount={callActivityCount}
        onPageChange={(page: number, rowsPerPage: number) => {
          dispatch(setIsDataLoaded(false));
          getCallActivity({
            variables: {
              filter: {
                status: [
                  CallPayloadStatusEnum.BUSY,
                  CallPayloadStatusEnum.CANCELED,
                  CallPayloadStatusEnum.NO_ANSWER,
                  CallPayloadStatusEnum.FAILED,
                  CallPayloadStatusEnum.COMPLETED,
                ],
              },
              pagination: {
                offset: page * rowsPerPage,
                limit: rowsPerPage,
              },
            },
          }).finally(() => {
            dispatch(setIsDataLoaded(true));
          });
        }}
        columns={columns}
        paginationEnabled={paginationEnabled}
        loading={!isDataLoaded}
        enableCardView={true} // Enable cards conditionally
        cardRender={cardView}
        emptyCardRender={emptyCardView}
        forceCardView={forceCardView}
      />
    );
  };

  if (showTableOnly) {
    return <CallActivityTable />;
  }

  return (
    <NucleusPage
      title="Call Activity"
      header={'Call Activity'}
      content={
        <Box sx={{ height: '100%' }}>
          <CallActivityTable />
        </Box>
      }
    />
  );
}

export default CallActivity;
