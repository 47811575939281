import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

export enum EmailAccountsSearchCriteria {
  Email = 'email',
}

type searchBarProps = {
  options?: {
    verifiedOnly?: boolean;
    hideStatusFilter?: boolean;
    hideCriteria?: boolean;
    placeholder?: string;
  };
  searchData: (
    searchValue: string,
    searchCriteria?: EmailAccountsSearchCriteria,
    verified?: boolean,
    unverified?: boolean,
  ) => void;
};

const EmailAccountsListAdvanceSearchBar = (props: searchBarProps) => {
  const { searchData, options } = props;
  const [searchValue, setSearchValue] = useState('');
  const [searchCriteria, setSearchCriteria] = useState<EmailAccountsSearchCriteria>(EmailAccountsSearchCriteria.Email);
  const [statusVerified, setStatusVerified] = useState<boolean>(false);
  const [statusUnverified, setStatusUnverified] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>('Search');

  const searchCriteriaOptions = [{ type: EmailAccountsSearchCriteria.Email, displayName: 'Email', shortName: 'email' }];

  useEffect(() => {
    setPlaceholder(
      searchCriteria
        ? !options?.hideCriteria
          ? `Search by ${searchCriteriaOptions.find(option => option.type === searchCriteria)?.shortName.toLowerCase()}`
          : (options?.placeholder as string)
        : 'Search',
    );
    if (options) {
      if (options.verifiedOnly) {
        setStatusVerified(true);
      }
    }
  }, [searchCriteria]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleSearchData = () => {
    searchData(searchValue, searchCriteria, statusVerified, statusUnverified);
  };
  const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchData();
    }
  };

  const handleFieldChange = (event: any) => {
    setSearchCriteria(event.target.value);
  };

  const handleVerifiedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusVerified(event.target.checked);
    searchData(searchValue, searchCriteria, event.target.checked, statusUnverified);
  };

  const handleUnverifiedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusUnverified(event.target.checked);
    searchData(searchValue, searchCriteria, statusVerified, event.target.checked);
  };

  const handleClear = () => {
    setSearchValue('');
    searchData('', searchCriteria, statusVerified, statusUnverified);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sx={{ flex: 1 }}>
        <FormControl fullWidth>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: '4px',
              overflow: 'hidden',
              height: '3rem',
              flexGrow: 1,
            }}
          >
            <TextField
              placeholder={placeholder}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ marginLeft: '0.5rem' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
              style={{ flex: 1 }}
            />
            {searchValue && <Button onClick={handleClear}>Clear</Button>}
            <Button onClick={handleSearchData} style={{ marginRight: '0.5rem' }}>
              Search
            </Button>
          </div>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        <FormControl
          variant="outlined"
          style={{
            minWidth: '200px',
            display: options?.hideCriteria ? 'none' : '',
          }}
        >
          <Select
            value={searchCriteria}
            onChange={handleFieldChange}
            input={<Input disableUnderline />}
            displayEmpty
            renderValue={selected =>
              selected ? (
                searchCriteriaOptions.find(option => option.type === selected)?.displayName
              ) : (
                <em>Select Search Criteria</em>
              )
            }
            variant="standard"
          >
            {searchCriteriaOptions.map(field => (
              <MenuItem key={field.type} value={field.type}>
                <ListItemText primary={field.displayName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs="auto">
        <Box hidden={options?.hideStatusFilter}>
          Status: {''}
          <FormControlLabel control={<Checkbox onChange={handleVerifiedChange} />} label="Verified" />
          <FormControlLabel control={<Checkbox onChange={handleUnverifiedChange} />} label="Unverified" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmailAccountsListAdvanceSearchBar;
