import React, { useContext, useEffect, useState } from 'react';
import { GridPaginationModel } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import LetterAvatar from '../../components/Avatar';
import EllipsisText from '../../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../../components/DateFormatter';
import { stringFormatter } from '../../components/StringFormatter';
import { CrmInterestTypeEnums, CrmLead, CrmLeadsPaged } from '../../types/crm/lead';
import { blue, green, orange } from '@mui/material/colors';
import { AddressFormatter } from '../../types/AddressFormatter';
import { CrmTaskSchedule } from '../../types/crm/taskSchedule/taskSchedule';
import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_LEADS_LIST, GET_USER } from '../../../common/graphQL';
import { setIsDataLoaded, setLeadsData, setTotalLeads } from '../../../redux/contactsSlice';
import NucleusPage from '../../components/NucleusPage';
import { useTranslation } from 'react-i18next';
import { CrmUser, IdentityUsers } from '../../types/crm/user';
import { AuthContext } from '../../../contexts/CognitoContext';
import SearchBar from '../../components/SearchBar';
import { Add as AddIcon } from '@mui/icons-material';
import { SmallButton } from '../task/components/TaskModalHeader';
import { PhoneNumberFormmater } from '../../components/PhoneNumberFormmater';
import NucleusTable, { DataRow, NucleusTableColumn } from '../../components/NucleusTable';
import { contactInterestColors } from './interest';

const Contacts = (props: { showTableOnly?: boolean; forceCardView?: boolean; paginationEnabled?: boolean }) => {
  const { showTableOnly = false, forceCardView = false, paginationEnabled = true } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState<CrmUser>();
  const { user } = useContext(AuthContext) || {};
  const userID = user && user['custom:nucleus-id'];
  const [paginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25, // Adjust according to your needs
  });

  useQuery<IdentityUsers>(GET_USER, {
    variables: {
      filter: {
        userId: userID,
      },
    },
    onCompleted: result => {
      if (result) {
        setCurrentUser(result.identity.users[0]);
      }
    },
  });

  const columns: NucleusTableColumn[] = [
    {
      field: 'contact',
      headerName: 'Contact',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      editable: false,
      width: '15rem',
      renderCell: (_value, row) => {
        const id = row?.id ?? '';
        const name = row?.name ?? '';
        const contactNo = row?.contactNo ?? '';

        return (
          <React.Fragment>
            {id ? (
              <RouterLink to={`${id}`} style={{ textDecoration: 'none' }}>
                {name || contactNo ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {name && <LetterAvatar name={name ?? ''} sx={{ backgroundColor: 'orange' }} />}
                    <Box sx={{ pl: 2, flex: 1, display: 'inline-grid' }}>
                      {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
                      {contactNo ? (
                        <EllipsisText content={PhoneNumberFormmater({ phoneNumber: contactNo })} />
                      ) : (
                        <EllipsisText content={'Not Provided'} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <EllipsisText content="Not Provided" />
                )}
              </RouterLink>
            ) : (
              <EllipsisText content="Not Provided" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: false,
      width: '10rem',
      renderCell: (_value, row) => {
        const assignedBy = row?.assignedByUser ?? '';
        const firstName = row?.assignedByUser?.firstName ?? '';
        const lastName = row?.assignedByUser?.lastName ?? '';
        const name = `${firstName} ${lastName}`.trim() ?? '';
        const createdAt = row?.createdAt
          ? DateFormatter({ date: row?.createdAt, dateFormatType: DateFormatType.LongDate })
          : '';
        const source = row?.leadForm?.platform ?? '';

        const getSourceIcon = (platform: string) => {
          const icon =
            platform === 'FACEBOOK'
              ? '/static/img/logo/fb.png'
              : platform === 'INSTAGRAM'
                ? '/static/img/logo/instagram.png'
                : '';
          return icon;
        };

        return (
          <Box sx={{ display: 'inline-grid', textAlign: 'center' }}>
            {assignedBy || source || createdAt ? (
              <React.Fragment>
                {assignedBy || source ? (
                  <React.Fragment>
                    {assignedBy ? (
                      <EllipsisText content={name} />
                    ) : (
                      <Tooltip title={stringFormatter(source)}>
                        <Box sx={{ display: 'center', alignItems: 'center', justifyContent: 'center' }}>
                          <Avatar
                            sx={{
                              borderRadius: 0,
                              height: 25,
                              width: 25,
                            }}
                            src={getSourceIcon(source)}
                            alt={source}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </React.Fragment>
                ) : (
                  <EllipsisText content="Unknown" />
                )}
                {createdAt ? <EllipsisText content={createdAt} /> : <EllipsisText content={'Unknown'} />}
              </React.Fragment>
            ) : (
              <EllipsisText content="Unknown" />
            )}
          </Box>
        );
      },
    },
    {
      field: 'interest',
      headerName: 'Interest',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: '7rem',
      renderCell: (_value, row) => {
        let backgroundColor;
        const interestTypeName = row?.interestType?.interestTypeName ?? '';
        let isInterestTypeExist = true;

        switch (interestTypeName) {
          case CrmInterestTypeEnums.ROOF:
            backgroundColor = orange[500];
            break;
          case CrmInterestTypeEnums.SOLAR:
            backgroundColor = green[500];
            break;
          case CrmInterestTypeEnums.HVAC:
            backgroundColor = blue[500];
            break;
          default:
            isInterestTypeExist = false;
            break;
        }

        return (
          <React.Fragment>
            {interestTypeName && isInterestTypeExist ? (
              <Chip
                size="small"
                sx={{
                  color: 'white',
                  padding: '0',
                  borderRadius: '20px',
                  backgroundColor,
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                label={<EllipsisText content={interestTypeName} />}
              />
            ) : (
              <EllipsisText content="Not Provided" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      editable: false,
      width: '18rem',
      renderCell: (_value, row) => {
        const address = row?.address ? AddressFormatter({ address: row.address }) : '';

        return (
          <React.Fragment>
            {address ? <EllipsisText content={address} /> : <EllipsisText content="Not Provided" />}
          </React.Fragment>
        );
      },
    },
    {
      field: 'activity',
      headerName: 'Last Activity',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      width: '10rem',
      renderCell: (_value, row) => {
        const taskSchedules = row?.taskSchedules ?? []; // Ensure taskSchedules is an array
        const taskSchedulesWithHistory = taskSchedules.filter((item: CrmTaskSchedule) => item.history?.length > 0);

        // Check if there is any valid task schedule with history
        if (taskSchedulesWithHistory.length === 0) {
          return <EllipsisText content="No Latest Activity" />;
        }

        const latestTaskSchedule = taskSchedulesWithHistory[taskSchedulesWithHistory.length - 1];
        const latestHistory = latestTaskSchedule?.history?.[latestTaskSchedule.history.length - 1] ?? null;

        // Check if the latest history exists
        if (!latestHistory) {
          return <EllipsisText content="No Latest Activity" />;
        }

        const latestActivity = latestHistory?.eventSubject ? stringFormatter(latestHistory.eventSubject) : '';
        const activityDate = latestHistory?.timestamp
          ? DateFormatter({ date: latestHistory?.timestamp, dateFormatType: DateFormatType.LongDate })
          : '';

        return (
          <React.Fragment>
            {latestActivity || activityDate ? (
              <Box sx={{ display: 'inline-grid', textAlign: 'center' }}>
                {latestActivity ? <EllipsisText content={latestActivity} /> : <EllipsisText content={'Unknown'} />}
                {activityDate ? <EllipsisText content={activityDate} /> : <EllipsisText content={'Unknown'} />}
              </Box>
            ) : (
              <EllipsisText content="No Latest Activity" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: false,
      renderCell: (_value, row) => {
        const assignedTo = row?.assignedToUser ?? '';
        const firstName = row?.assignedToUser?.firstName ?? '';
        const lastName = row?.assignedToUser?.lastName ?? '';
        const name = `${firstName} ${lastName}`.trim() ?? '';
        return (
          <React.Fragment>
            {assignedTo ? (
              name ? (
                <EllipsisText content={name} />
              ) : (
                <EllipsisText content="Unknown" />
              )
            ) : (
              <EllipsisText content="Unassigned" />
            )}
          </React.Fragment>
        );
      },
    },
  ];
  const leadData = useAppSelector<CrmLead[]>((state: RootState) => state.contacts.data);
  const leadsCount = useAppSelector<number>((state: RootState) => state.contacts.totalLeads);
  const isDataLoaded = useAppSelector<boolean>((state: RootState) => state.contacts.isDataLoaded);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const navigate = useNavigate();

  const [getContacts, { data: contactResults }] = useLazyQuery<CrmLeadsPaged>(GET_LEADS_LIST, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!isDataLoaded) {
      getContacts({
        variables: {
          filter: selectedFields.reduce((acc, field) => {
            // @ts-ignore
            acc[field] = searchString;
            // Add more fields as needed
            return acc;
          }, {}),
          pagination: {
            offset: paginationModel.page * paginationModel.pageSize, // GraphQL pagination is usually 1-indexed
            limit: paginationModel.pageSize,
          },
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchLeads = (searchValue: string, searchFields: string[]) => {
    setSearchString(searchValue);
    setSelectedFields(searchFields);
    dispatch(setIsDataLoaded(false));
    getContacts({
      variables: {
        filter: searchFields.reduce((acc, field) => {
          // @ts-ignore
          acc[field] = searchValue;
          // Add more fields as needed
          return acc;
        }, {}),
        pagination: {
          offset: paginationModel.page * paginationModel.pageSize, // GraphQL pagination is usually 1-indexed
          limit: paginationModel.pageSize,
        },
      },
    }).finally(() => {
      dispatch(setIsDataLoaded(true));
    });
  };

  useEffect(() => {
    if (contactResults && contactResults?.crm?.leadsPaged?.leads) {
      dispatch(setLeadsData(contactResults.crm?.leadsPaged?.leads));
      dispatch(setIsDataLoaded(true));
      dispatch(setTotalLeads(contactResults?.crm?.leadsPaged?.totalCount));
    }
  }, [contactResults, dispatch]);

  const cardView = (row: DataRow): React.ReactNode => {
    const data = row as CrmLead; // Adjust this depending on the structure of your row
    const name = data?.name ?? '';
    const contactNo = data.contactNo ?? '';
    const interestTypeName = data.interestType.interestTypeName as CrmInterestTypeEnums;
    const address = data?.address ? AddressFormatter({ address: data.address }) : '';
    const assignedTo = data?.assignedToUser ?? '';
    const firstName = assignedTo?.firstName ?? '';
    const lastName = assignedTo?.lastName ?? '';
    const assignedToName = `${firstName} ${lastName}`.trim() ?? '';

    return (
      <RouterLink to={`/crm/contacts/${data.id}`} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column', // Allow vertical stacking for rows
            p: 1.5,
            mb: 1,
            borderLeft: 4,
            borderRadius: 0,
            borderColor: contactInterestColors[interestTypeName],
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                mr: 2,
                p: 1,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LetterAvatar name={name} sx={{ backgroundColor: 'orange' }} />
            </Box>
            <Box sx={{ flexGrow: 1, width: '100%' }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                }}
              >
                {name} &nbsp;&nbsp;
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {contactNo}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}></Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
              <Typography variant="body2" color="text.secondary">
                <EllipsisText content={address} />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {assignedTo ? (
                  assignedToName ? (
                    <EllipsisText content={assignedToName} />
                  ) : (
                    <EllipsisText content="Unknown" />
                  )
                ) : (
                  <EllipsisText content="Unassigned" />
                )}
              </Typography>
            </Box>
          </Box>
        </Card>
      </RouterLink>
    );
  };

  const emptyCardView = (): React.ReactNode => {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          p: 1.5,
          mb: 1,
          borderLeft: 4,
          borderRadius: 0,
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <Box
          sx={{
            mr: 2,
            p: 1,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Skeleton variant="circular" width={32} height={32} />
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <Typography variant="body1">
            <Skeleton width="70%" />
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            <Skeleton width="50%" />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
          <Typography variant="body2" color="text.secondary">
            <Skeleton width="40%" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Skeleton width="30%" />
          </Typography>
        </Box>
      </Card>
    );
  };

  const ContactsTable = (): React.ReactNode => {
    return (
      <React.Fragment>
        <NucleusTable
          totalCount={leadsCount}
          loading={!isDataLoaded}
          data={leadData ?? []}
          enableCardView={true} // Enable cards conditionally
          cardRender={cardView}
          paginationEnabled={paginationEnabled}
          emptyCardRender={emptyCardView}
          forceCardView={forceCardView}
          columns={columns}
          onPageChange={(page: number, rowsPerPage: number) => {
            dispatch(setIsDataLoaded(false));
            getContacts({
              variables: {
                filter: selectedFields.reduce((acc, field) => {
                  // @ts-ignore
                  acc[field] = searchString;
                  // Add more fields as needed
                  return acc;
                }, {}),
                pagination: {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage,
                },
              },
            }).finally(() => {
              dispatch(setIsDataLoaded(true));
            });
          }}
        />
      </React.Fragment>
    );
  };

  if (showTableOnly) {
    return (
      <React.Fragment>
        <SearchBar placeholder={'Search Contacts'} showFieldOptions={false} searchData={searchLeads} />
        <ContactsTable />
      </React.Fragment>
    );
  }

  return (
    <NucleusPage
      title={'Contacts'}
      header={`${t('Welcome back')}${currentUser ? `, ${currentUser?.firstName}` : ''}`}
      loading={!isDataLoaded}
      actions={
        <Tooltip title="Add new contact">
          <SmallButton size="small" onClick={() => navigate('/crm/new-contact')}>
            <AddIcon /> Add New Contact
          </SmallButton>
        </Tooltip>
      }
      content={
        <React.Fragment>
          <SearchBar placeholder={'Search Contacts'} searchData={searchLeads} />
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <ContactsTable />
              </Box>
            </CardContent>
          </Card>
        </React.Fragment>
      }
    />
  );
};

export default Contacts;
