import React from 'react';
import { PhoneMissed, PhoneOutgoing, PhoneIncoming, Voicemail } from 'react-feather';
import { CallType } from '../../types/crm/callActivity';

export const callTypeColors: Record<CallType | 'DEFAULT', string> = {
  [CallType.INCOMING_CALL]: '#28a745',
  [CallType.OUTGOING_CALL]: '#28a745',
  [CallType.VOICEMAIL]: '#FFA500',
  [CallType.MISSED_CALL]: '#dc3545',
  DEFAULT: '#dc3545',
};

interface CallStatusIconProps {
  status: CallType;
  textColor?: string;
  size?: number;
}

const CallStatusIcon: React.FC<CallStatusIconProps> = ({ status, textColor, size }) => {
  const color = textColor ? textColor : callTypeColors[status] || callTypeColors.DEFAULT;
  const sizeStyle = size ? { width: size, height: size } : {};

  switch (status) {
    case CallType.INCOMING_CALL:
      return <PhoneIncoming color={color} style={sizeStyle} />;
    case CallType.OUTGOING_CALL:
      return <PhoneOutgoing color={color} style={sizeStyle} />;
    case CallType.VOICEMAIL:
      return <Voicemail color={color} style={sizeStyle} />;
    default:
      return <PhoneMissed color={color} style={sizeStyle} />;
  }
};

export default CallStatusIcon;
