export enum DateFormatType {
  Standard = 'YYYY-MM-DD',
  StandardWithTime = 'YYYY-MM-DD HH:MM:SS',
  ShortMonthWithTime = 'MMM DD, YYYY h:mm A',
  DayOfWeek = 'Day',
  ShortDate = 'MM/DD/YYYY',
  LongDate = 'MMMM DD, YYYY',
  LongDateWithTime = 'LONG_DATE_WITH_TIME',
  ISOWithTimezone = 'ISO_WITH_TIMEZONE',
  ShortDateWithTime = 'MM/DD/YYYY h:mm A',
  Time = 'h:mm A',
}

export interface DateFormatterProps {
  date: string | number | Date;
  dateFormatType: DateFormatType;
}

export const DateFormatter = (props: DateFormatterProps): string => {
  const { date: dateProps, dateFormatType } = props;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const prepareDate = (date: string | number | Date): Date => {
    if (typeof date === 'string' && !isNaN(Number(date))) {
      let timestamp = Number(date);
      // Check if timestamp is in milliseconds (length > 10)
      if (timestamp.toString().length > 10) {
        timestamp = Math.floor(timestamp / 1000); // Convert milliseconds to seconds
      }
      return new Date(timestamp * 1000);
    }

    if (typeof date === 'number') {
      let timestamp = date;
      // Check if timestamp is in milliseconds (length > 10)
      if (timestamp.toString().length > 10) {
        timestamp = Math.floor(timestamp / 1000); // Convert milliseconds to seconds
      }
      return new Date(timestamp * 1000);
    }

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      throw new Error(`Invalid date: ${date}`);
    }
    return parsedDate;
  };

  const formatDate = (date: Date, formatType: DateFormatType): string => {
    switch (formatType) {
      case DateFormatType.Standard:
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
          2,
          '0',
        )}`;
      case DateFormatType.StandardWithTime:
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
          2,
          '0',
        )} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
          date.getSeconds(),
        ).padStart(2, '0')}`;
      case DateFormatType.ShortMonthWithTime: {
        const month = months[date.getMonth()].slice(0, 3);
        const hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${month} ${date.getDate()}, ${date.getFullYear()} at ${formattedHours}:${String(
          date.getMinutes(),
        ).padStart(2, '0')} ${ampm}`;
      }
      case DateFormatType.DayOfWeek:
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
      case DateFormatType.ShortDate:
        return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(
          2,
          '0',
        )}/${date.getFullYear()}`;
      case DateFormatType.LongDate:
        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
      case DateFormatType.LongDateWithTime:
        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${String(
          date.getHours() % 12 || 12,
        )}:${String(date.getMinutes()).padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
      case DateFormatType.ISOWithTimezone:
        return date.toISOString();
      case DateFormatType.ShortDateWithTime: {
        const hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(
          2,
          '0',
        )}/${date.getFullYear()} ${formattedHours}:${String(date.getMinutes()).padStart(2, '0')} ${ampm}`;
      }
      case DateFormatType.Time: {
        const hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${String(date.getMinutes()).padStart(2, '0')} ${ampm}`;
      }
      default:
        return 'Invalid Format';
    }
  };

  const preparedDate = prepareDate(dateProps);
  return formatDate(preparedDate, dateFormatType);
};
