import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation CREATE_NOTE($input: IdentityNoteInput!) {
    identity {
      user {
        createNote(input: $input) {
          success
        }
      }
    }
  }
`;

export const UPLOAD_VOICE_MESSAGE = gql`
  mutation UploadVoiceMessage($input: IdentityUploadVoiceMessageInput!) {
    identity {
      twilio {
        uploadVoiceMessage(input: $input) {
          success
          file {
            service
            url
            publicId
            signature
            resourceType
            etag
            createdAt
            messageType
          }
          message
        }
      }
    }
  }
`;

export const SWITCH_ACTIVE_TENANT = gql`
  mutation SwitchActiveTenant($tenantId: String!) {
    identity {
      user {
        switchActiveTenant(tenantId: $tenantId) {
          success
        }
      }
    }
  }
`;
