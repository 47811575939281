import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LeadMap from '../../task/components/LeadMap';
import { Phone } from '@mui/icons-material';
import { Edit, Mail, MessageSquare } from 'react-feather';
import Avatar from '@mui/material/Avatar';
import React, { useState } from 'react';
import { CrmLead } from '../../../types/crm/lead';
import { ActivityType } from '../../task/taskTypes';
import ContactLeadModal from './communication/ContactLeadModal';
import ContactProfileIcon, { ProfileIconSizeType } from './ContactProfileIcon';
import { CrmUser } from '../../../types/crm/user';
import ContactEdit from './ContactEdit';

export const ContactDetails = (props: {
  currentLead: CrmLead;
  currentUser: CrmUser;
  onlyShowActions?: boolean;
  compact?: boolean;
}) => {
  const { currentLead, currentUser, onlyShowActions, compact } = props;
  const [selectedActivity, setSelectedActivity] = useState<ActivityType>(ActivityType.DEFAULT);
  const [showEditContact, setShowEditContact] = useState<boolean>(false);
  const googleMapInitialPosition = {
    lat: Number(currentLead?.address?.lat) ?? 12.9716,
    lng: Number(currentLead?.address?.lng) ?? 77.5946,
  };

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleActivitySelection = (activity: ActivityType) => {
    setSelectedActivity(activity);
  };

  const onHandleClick = (action: ActivityType) => {
    setOpenModal(true);
    handleActivitySelection(action);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const closeEditModal = () => {
    setShowEditContact(false);
  };

  const ContactActions: React.FC<{}> = () => {
    return (
      <Grid container justifyContent="left" alignContent="center" sx={{ my: '0rem' }} spacing={3}>
        <ContactProfileIcon
          DisplayIcon={Phone}
          label={'Phone'}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.VOICE);
            },
            disabled: !currentUser.twilio?.projectedAddress,
          }}
          toolTipProps={{
            title: !currentUser.twilio?.projectedAddress ? 'Your account cannot execute calls' : '',
            disableHoverListener: !!currentUser.twilio?.projectedAddress,
          }}
        />
        <ContactProfileIcon
          DisplayIcon={Mail}
          label={'Email'}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.EMAIL);
            },
            disabled: !currentUser.twilio?.sendGridVerified,
          }}
          toolTipProps={{
            title: !currentUser.twilio?.sendGridVerified ? 'Your SendGrid account is not verified' : '',
            disableHoverListener: !!currentUser.twilio?.sendGridVerified,
          }}
        />
        <ContactProfileIcon
          DisplayIcon={MessageSquare}
          label={'SMS'}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.SMS);
            },
            disabled: !currentUser.twilio?.projectedAddress,
          }}
          toolTipProps={{
            title: !currentUser.twilio?.projectedAddress ? 'Your account cannot send sms' : '',
            disableHoverListener: !!currentUser.twilio?.projectedAddress,
          }}
        />
      </Grid>
    );
  };

  const ContactActionsCompact: React.FC<{}> = () => {
    return (
      <Grid container justifyContent="left" alignContent="center" sx={{ my: '0rem' }} spacing={3}>
        <ContactProfileIcon
          DisplayIcon={Phone}
          size={ProfileIconSizeType.XS}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.VOICE);
            },
            disabled: !currentUser.twilio?.projectedAddress,
          }}
        />
        <ContactProfileIcon
          DisplayIcon={Mail}
          size={ProfileIconSizeType.XS}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.EMAIL);
            },
            disabled: !currentUser.twilio?.sendGridVerified,
          }}
        />
        <ContactProfileIcon
          DisplayIcon={MessageSquare}
          size={ProfileIconSizeType.XS}
          iconTypeButton={{
            onClick: () => {
              onHandleClick(ActivityType.SMS);
            },
            disabled: !currentUser.twilio?.projectedAddress,
          }}
        />
      </Grid>
    );
  };

  if (onlyShowActions) {
    if (!compact) {
      return (
        <React.Fragment>
          <ContactActions />
          <ContactLeadModal
            openModal={openModal}
            onClose={onClose}
            selectedActivity={selectedActivity}
            handleActivitySelection={handleActivitySelection}
            userIdentity={currentUser}
            currentLead={currentLead}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ContactActionsCompact />
        <ContactLeadModal
          openModal={openModal}
          onClose={onClose}
          selectedActivity={selectedActivity}
          handleActivitySelection={handleActivitySelection}
          userIdentity={currentUser}
          currentLead={currentLead}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Card style={{ borderRadius: '0px', position: 'relative', marginBottom: '1rem' }}>
        <CardContent style={{ padding: '0' }}>
          <Box style={{ height: '180px' }}>
            <LeadMap
              position={googleMapInitialPosition}
              useMarker={true}
              zoom={20}
              options={{
                streetViewControl: false,
              }}
              isMarkerdraggable={false}
            />
          </Box>
          <Box sx={{ padding: '1.5rem', mt: '1.8rem' }}>
            <Typography variant={'h4'}>
              {currentLead.name}
              <Button
                size={'small'}
                style={{ minWidth: 'unset', margin: '0 0 0 1rem', padding: 0 }}
                onClick={() => setShowEditContact(true)}
              >
                <Edit size={16} />
              </Button>
            </Typography>
            <ContactActions />
          </Box>
          <Box style={{ position: 'absolute', top: 140, left: 30, zIndex: 1000 }}>
            <Avatar
              sx={{
                height: '5rem',
                width: '5rem',
                backgroundColor: '#282a33',
                outline: '.4rem solid #fff',
              }}
            >
              <Typography variant={'h1'}>
                {currentLead?.name
                  ?.split(' ')
                  .map(part => part.charAt(0))
                  .join('')}
              </Typography>
            </Avatar>
          </Box>
        </CardContent>
      </Card>
      <ContactEdit open={showEditContact} lead={currentLead} onClose={closeEditModal} />
      <ContactLeadModal
        openModal={openModal}
        onClose={onClose}
        selectedActivity={selectedActivity}
        handleActivitySelection={handleActivitySelection}
        userIdentity={currentUser}
        currentLead={currentLead}
      />
    </React.Fragment>
  );
};
