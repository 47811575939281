import { gql } from '@apollo/client';

export const UPDATE_USER_PROFILE = gql`
  mutation updateProfile($input: IdentityUserUpdateProfileInput!) {
    identity {
      admin {
        user {
          updateProfile(input: $input) {
            userId
            success
            message
          }
        }
      }
    }
  }
`;
