import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';

import { Alert, Box, CssBaseline, LinearProgress } from '@mui/material';
import { spacing } from '@mui/system';

import GlobalStyle from '../../components/GlobalStyle';
import Navbar from '../../components/navbar/Navbar';
import dashboardItems from './dashboardItems';
import Sidebar from '../../components/sidebar/Sidebar';
import Settings from '../../components/Settings';
import { useQuery } from '@apollo/client';
import { CrmUser, IdentityUsers } from '../types/crm/user';

import { AuthContext } from '../../contexts/CognitoContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { GET_USER } from '../../common/graphQL';
import MicrophonePermissionChecker from '../components/voiceCall/MicrophonePermissionChecker';

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
`;

const FixedNavbar = styled.div`
  position: absolute; // Changed from fixed
  top: 0;
  width: 100%;
  z-index: 1100;
`;

export const AlertContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 64px;
`;

const Paper = styled(Box)(spacing);

const MainContent = styled(Paper)(() => ({
  // flex: 1,
  // display: 'flex',
  // flexDirection: 'column',
  background: 'none',
  // height: 'calc(100vh - 64px)', // Assuming the header height is 64px
  // overflowY: 'auto',
  marginTop: '4rem',
  // [theme.breakpoints.up('md')]: {
  //   width: 'calc(100vw - 260px)', // Assuming the navigation width is 260px on non-mobile
  // },
  // [theme.breakpoints.down('md')]: {
  //   width: '100vw', // Full width on mobile
  // },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    flex: 'none',
  },
  '& .MuiPaper-root .MuiPaper-root': {
    boxShadow: 'none',
  },
}));

interface DashboardType {
  children?: React.ReactNode;
}

/**
 *
 * @param root0
 */
const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const { user } = useContext(AuthContext) || {};
  const userID = user && user['custom:nucleus-id'];
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showSendGridEmailReminder, setShowSendGridEmailReminder] = useState(false);
  const [updatedUser, setUpdatedUser] = useState<CrmUser | null>(null);

  const { loading } = useQuery<IdentityUsers>(GET_USER, {
    variables: {
      filter: {
        userId: userID,
      },
    },
    onCompleted: data => {
      const userDetails = data.identity?.users[0] ?? ({} as CrmUser);

      // Check if user has phone number to toggle features like voice, inbox.
      const hasPhoneNumber = userDetails?.twilio?.projectedAddress ?? false;

      // Check if user has SendGrid verified email
      const isSendGridVerified = userDetails?.twilio?.sendGridVerified ?? false;

      // Add permissions dynamically
      const updatedUserDetails = {
        ...userDetails,
        permissions: [
          ...(userDetails.permissions || []),
          ...(hasPhoneNumber // Add Twilio permissions if user has phone number
            ? [
                { name: 'feature:twilio:messaging' },
                { name: 'feature:twilio:voice:outbound' },
                { name: 'feature:twilio:voice:inbound' },
              ]
            : []),
          ...(isSendGridVerified ? [{ name: 'feature:twilio:sendgrid:email' }] : []), // Add SendGrid permissions if user has phone number
        ],
      };

      setUpdatedUser(updatedUserDetails);
      setShowSendGridEmailReminder(!isSendGridVerified);

      if (!isDataFetched) {
        setIsDataFetched(true);
      }
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  if (loading || !updatedUser) {
    return <LinearProgress />;
  }

  return (
    <Root>
      <CurrentUserContext.Provider value={updatedUser}>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar PaperProps={{ style: { width: drawerWidth } }} items={dashboardItems} />
          </Box>
        </Drawer>
        <AppContent>
          <FixedNavbar>
            <Navbar onDrawerToggle={handleDrawerToggle} />
          </FixedNavbar>
          <AlertContainer>
            {updatedUser?.twilio?.projectedAddress && <MicrophonePermissionChecker />}
            {showSendGridEmailReminder && (
              <Alert
                variant="filled"
                severity="warning"
                sx={{ borderRadius: 0 }}
                onClose={() => {
                  setShowSendGridEmailReminder(false);
                }}
              >
                Please verify your email address for SendGrid. Thank you!
              </Alert>
            )}
          </AlertContainer>
          <MainContent sx={{ p: 5 }} flexGrow={1}>
            {children}
            <Outlet />
          </MainContent>
        </AppContent>
        <Settings />
      </CurrentUserContext.Provider>
    </Root>
  );
};

export default Dashboard;
