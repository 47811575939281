import { gql } from '@apollo/client';

export const CALCULATE_PRODUCT = gql`
  query CalculateProduct($projectId: ID!, $designId: String!) {
    projectManagement {
      calculateProduct(projectId: $projectId, designId: $designId) {
        adder
        cost
        description
        name
      }
    }
  }
`;

export const GET_PROPOSAL_SUMMARY = gql`
  query ProposalSummary($proposalId: ID!) {
    projectManagement {
      proposalSummary(proposalId: $proposalId) {
        firstName
        lastName
        email
        phone
        address
        cost
        utilityCompany
        utilityRate
        currentMonthlyBill
        currentAnnualBill
        monthlyConsumption
        gallonsOfGasSaved
        treesSaved
        tonsOfWasteSaved
        offset
        systemSize
        panel
        panelQuantity
        inverter
        monthlyProduction
        proposalImage
        taxCredit
        estimated25YearProduction
        bankTerm
        bankApr
        bankName
        bankLogo
        payment
        secondPayment
        savingsAfterYear5
        savingsAfterYear10
        savingsAfterYear15
        savingsAfterYear20
        savingsAfterYear25
        goingSolarPayments
        goingSolarCumulative
        utilityCosts
        utilityCostsCumulative
      }
    }
  }
`;

export const GET_CONTRACT_PDF = gql`
  query ProjectManagement($projectId: ID!, $proposalId: ID) {
    projectManagement {
      project(id: $projectId) {
        proposal(proposalId: $proposalId) {
          contract {
            pdf
          }
        }
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query GetProjects($pagination: ProjectManagementPaginationInput, $search: ProjectManagementProjectSearch) {
    projectManagement {
      projectsPaged(pagination: $pagination, search: $search) {
        totalCount
        projects {
          id
          customer {
            firstName
            lastName
            email
            phone
            address
            city
            state
            zip
            block
            lot
            crossStreet
          }
          overallStatus {
            completionPercentage
            status
          }
          phase {
            displayName
            name
            orderNo
          }
        }
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query GetProjectByID($projectId: ID!) {
    projectManagement {
      project(id: $projectId) {
        id
        financing {
          agreementStatus
          financeAmount
          loanId
          proposal {
            id
            finance {
              name
              term
              apr
              offerId
            }
          }
        }
        siteAssessment {
          createdAt
          scheduledById
          createdById
          projectId
          updatedAt
          status
          surveyDate
          systemType
          rackingType
          utilityEquipmentLocation
          mainServicePanelManufacturer
          mainBreakerSize
          subPanelForInterconnection
          electricalEquipmentAccessible
          submitted
        }
        documents {
          id
          type
          service
          url
          publicId
          signature
          resourceType
          etag
          createdAt
          latitude
          longitude
        }
        proposal {
          id
          proposalType
          cost
          systemSize
          finance {
            apr
            name
            paymentFactor1
            term
            offerId
          }
          product {
            adder
            cost
            description
            name
          }
          contract {
            agreementStatus
            amount
            coCustomerFirstName
            coCustomerLastName
            proposalId
          }
          bankingLoans {
            ... on ProjectManagementGoodLeapLoansOverview {
              connectedParties {
                name
                organizationId
                organizationTypes
              }
              loans {
                availableActions {
                  name
                  type
                }
                loanDetails {
                  id
                  createdAt
                  referenceNumber
                  status
                  offerId
                  installationCategoryId
                }
                timelineSections {
                  title
                  key
                  status
                  dateComplete
                  items
                  milestone
                }
              }
            }
          }
        }
        designPlatformProjectId
        designPlatformType
        designPlatform {
          design {
            id
            name
            summary {
              designId
              designRequestId
              externalProviderId
              projectId
              createdAt
              systemSizeAc
              systemSizePtc
              systemSizeStc
              billOfMaterials {
                id
                componentType
                sku
                name
                manufacturerName
                quantity
              }
              energyProduction {
                upToDate
                annual
                annualOffset
              }
            }
            asset {
              assetType
              designId
              fileName
              projectId
              url
            }
            shareLinkUrl
            externalProviderId
          }
          consumptionProfile {
            utility
            monthlyEnergy
            monthlyBill
          }
        }
        customer {
          firstName
          lastName
          email
          phone
          address
          city
          state
          zip
          block
          lot
          crossStreet
        }
        company {
          id
          name
          address
          city
          state
          zip
          phone
          registrationNumber
          bankRate {
            bank
            term
            apr
            paymentFactor1
            paymentFactor19
            dealerFee
            enabled
          }
          financeOffers {
            bank
            states {
              state
              rates {
                offerId
                displayName
                term
                apr
                paymentFactor1
                paymentFactor19
                dealerFee
                nucleusAvailability
                updatedAt
              }
            }
          }
          companyIdentifier
          fax
          integrations
          pricing {
            tier {
              min
              max
              ppw
            }
            trench {
              gravel
              concrete
            }
            groundMount {
              ppw
            }
            foamRoof {
              ppw
            }
          }
          product {
            name
            description
            enabled
            cost
            pricingModel
            adder
          }
        }
        tenantId
        lead {
          id
          address {
            lat
            lng
            state
            streetName
            streetNo
            city
            zipCode
          }
          name
          contactNo
          email
          leadForm {
            platform
          }
          interestType {
            interestTypeName
          }
          conversation {
            conversationThread {
              messages {
                conversationSid
                body
                author
                dateCreated
                isMe
                sender
                attributes
              }
            }
            emails {
              body
              isMe
              createdAt
              receiver
              sender
              subject
            }
            friendlyName
            recordings {
              mediaUrl
              createdAt
            }
          }
          appointments {
            startTime
            endTime
            appointmentLocation {
              appointmentLocationName
            }
            appointmentGuests {
              email
              name
            }
            appointmentType {
              appointmentTypeName
              displayName
            }
            createdBy {
              firstName
              lastName
            }
          }
          taskSchedules {
            createdAt
            assignedTo {
              lastName
              firstName
            }
            dueDate
            remarks
            taskTitle
            taskType {
              taskTypeName
              displayName
            }
            isAutomated
            dispositionReason
            disposition {
              dispositionName
            }
            createdBy {
              firstName
              lastName
            }
          }
          callRecordings {
            dialer
            receiver
            duration
            summary
            startTime
            mediaUrl
            createdAt
            endTime
          }
        }
        phase {
          displayName
          name
          displayName
          orderNo
          requirements {
            id
            name
            component
            doneById
            dueDate
            completedDate
            createdAt
            status
            orderNo
            requirementGuideContent
          }
          group {
            groupName
            displayName
            orderNo
          }
        }
        process {
          name
          phases {
            name
            order
            status
            activities {
              name
              order
              status
              activityWorkflow
              required
            }
          }
        }
        submitted
        submittedDate
        utilityProvider
        utilityAccountNumber
        acDisconnect
        mainServicePanel
        productionMeterLocation
        productionMeterModel
        information {
          discovery {
            roof {
              needsQuote
              mainRoofType
              mainRoofSquares
              secondRoofType
              secondRoofSquares
              color
              notes
              price
            }
            tree {
              needsQuote
              price
            }
            trench {
              needsQuote
              gravelLinearFeet
              concreteLinearFeet
              notes
            }
            systemRemoval {
              needsQuote
              numberOfPanelsToRemove
              price
              notes
              ownedStatus
              existingSystemLienHolder
              installationCompany
            }
            hvac {
              needsQuote
              numberOfUnitsToRemove
              manufacturer1
              price1
              manufacturer2
              price2
              notes
            }
          }
          rafter {
            rafterSize
            rafterSpacing
          }
          engineering {
            permitPlanSetSubmissionDate
            permitPlanSetSubmissionStatus
            permitPlanSetIntakeDate
            permitPlanSetIntakeStatus
            notes
          }
          construction {
            financeIntake {
              status
              optionalComment
            }
            financeSubmission {
              status
              optionalComment
            }
            rebatesCommissionSubmission {
              newCustomerInstallBonusFundingDate
              closerBaseCommissionFundingDate
              closerCostKilowattCommissionFundingDate
              setterBaseCommissionFundingDate
              hasCustomerInstallBonus
              closerCommissionStatus
              setterBaseCommissionStatus
            }
            installationAppointmentScheduling {
              status
              attentionRequireReason
            }
            preConstructionFinanceSubmission {
              status
              attentionRequireReason
            }
            preConstructionFinanceIntake {
              status
              attentionRequireReason
              disbursementAmount
            }
            postConstructionFinanceSubmission {
              status
              attentionRequireReason
            }
            postConstructionFinanceIntake {
              status
              attentionRequireReason
              disbursementAmount
            }
            postConstructionFollowUp {
              status
              attentionRequireReason
            }
          }
          hoa {
            submission {
              hoaRequired
              required
              name
              website
              email
              phone
              contact
            }
            intake {
              approvalStatus
            }
          }
          ahj {
            intake {
              status
              requireMidPointInspection
              hasFloodZonePermit
            }
            inspectionIntake {
              structuralInspectionStatus
              electricalInspectionStatus
              fireInspectionStatus
              structuralInspectionNote
              electricalInspectionNote
              fireInspectionNote
              constructionInspectionStatus
              constructionInspectionFailureReason
              constructionPassedInspectionDate
              constructionFailedInspectionDate
              electricalInspectionFailureReason
              electricalPassedInspectionDate
              electricalFailedInspectionDate
              fireInspectionFailureReason
              firePassedInspectionDate
              fireFailedInspectionDate
              structuralInspectionFailureReason
              structuralPassedInspectionDate
              structuralFailedInspectionDate
            }
            inspectionSubmission {
              structuralInspectionStatus
              electricalInspectionStatus
              fireInspectionStatus
              structuralInspectionDate
              electricalInspectionDate
              fireInspectionDate
              constructionInspectionStatus
              constructionInspectionDate
            }
          }
          pti {
            intake {
              status
              optionalComment
            }
            submission {
              applicationStatus
              applicationNo
              customerAcknowledgement
              reason
            }
          }
          utility {
            acknowledgementIntake {
              customerUtility
            }
            inspectionIntake {
              inspectionDate
              inspectionStatus
              permissionToOperateDate
              correctionsRequiredDate
              attentionRequiredReason
            }
            inspectionSubmission {
              submissionStatus
              inspectionDate
            }
          }
          pto {
            intake {
              placedInService
              approvalStatus
              reason
            }
            financeIntake {
              status
              optionalComment
              fundingAmount
            }
            financeSubmission {
              status
              optionalComment
              attentionRequiredReason
            }
          }
          solarEquipment {
            quoteSubmission {
              equipmentSupplier
              status
              quoteNumber
            }
            quoteIntake {
              status
              amount
              number
            }
            orderSubmission {
              status
              attentionRequireReason
              cancelReason
            }
            orderIntake {
              status
              deliveryMethod
              invoiceAmount
              attentionRequireReason
              cancelReason
            }
          }
          solarInstallation {
            appointment {
              status
            }
            crewScheduling {
              installationCrew
            }
            constructionIntake {
              materialInspectionStatus
              materialInspectionRejectReason
              jobSafetyStatus
              jobSafetyRejectReason
              mountingSystemStatus
              mountingSystemRejectReason
              roofTopElectricStatus
              roofTopElectricRejectReason
              interConnectionStatus
              interConnectionRejectReason
              complianceStatus
              complianceRejectReason
            }
          }
          siteSurvey {
            propertyAssessment {
              gasUtilityMeter
              poolOnProperty
              poolHeater
              propertyShading
              notes
              assessmentStatus
              denialReason
            }
            standardElectricalAssessment {
              mainServicePanel
              servicePanelLocation
              mainServicePanelManufacturer
              mainBusBarRating
              mainBreakerRating
              serviceType
              subPanel
              assessmentStatus
              denialReason
            }
            electricUtilityMeterAssessment {
              utilityMeterLocation
              note
              assessmentStatus
              denialReason
            }
            siteAssessment {
              taskSubmission
              obstructionImpactDesign
              assessmentStatus
              denialReason
            }
            roofAssessment {
              roofCondition
              roofShading
              roofTypeACUnit
              note
              assessmentStatus
              denialReason
            }
            structuralAssessment {
              framing
              rafterSize
              rafterSpacing
              note
              assessmentStatus
              denialReason
              isStructuresFramingAccessible
            }
          }
          permit {
            intake {
              status
              printStatus
              attentionRequiredReason
              cancelReason
              moreInformationRequestReason
              firePermitStatus
              firePermitAttentionRequiredReason
              foodPlainPermitStatus
              foodPlainPermitAttentionRequiredReason
            }
            submission {
              status
              fee
              paymentMethod
              paymentStatus
              permitNumber
              constructionSiteRequirement
              ahjInspectionSchedulingMethod
              portal
              email
              phoneNo
              inspectionSiteRequirement
              specialRequestInstruction
              permitSubmissionDate
              isFirePermitRequired
              firePermitNumber
              firePermitSubmissionDate
              isFloodPermitRequired
              floodPermitNumber
              floodPermitSubmissionDate
            }
            payment {
              amount
              method
            }
          }
          projectIntake {
            financingSubmission {
              loanId
              loanDetailsConfirmation
              partnerPreApproval
              moreInformationRequired
            }
            financingNoticeToProceed {
              status
              optionalComment
              fundingReleaseDate
              contactVerificationStatus
              contactVerificationOptionalComment
              incomeVerificationStatus
              incomeVerificationOptionalComment
              propertyOwnershipVerificationStatus
              propertyOwnershipVerificationOptionalComment
              noticeToProceedDate
            }
            electricCompanyAccountDetails {
              accountHolder
              otherAccountHolder
              accountNumber
              accountHolderEmail
              accountHolderPhoneNo
            }
            electricCompanyIntake {
              accountHolder
              updatedAccountHolder
              accountNumber
              updatedAccountNumber
              meterNumber
            }
          }
          projectEngineering {
            siteSurveyIntake {
              propertyAssessment
              propertyAssessmentOptionalComment
              standardElectricalAssessment
              standardElectricalOptionalComment
              electricUtilityMeterAssessment
              electricUtilityMeterAssessmentOptionalComment
              siteAssessment
              siteAssessmentOptionalComment
              roofAssessment
              roofAssessmentOptionalComment
              structuralAssessment
              structuralAssessmentOptionalComment
            }
            planSetIntake {
              status
              projectRequirePEApproval
              projectRequireEEApproval
            }
            planSetSubmission {
              designer
              orderStatus
            }
            inverterMapping {
              inverterSiteId
            }
          }
          activation {
            systemMonitoringAccess {
              status
            }
            systemActivationSubmission {
              systemActivationType
              activationAppointmentDate
            }
            systemActivationIntake {
              systemActivationType
              attentionRequiredReason
            }
          }
          projectVerification {
            financingIntake {
              financingType
              applicationStatus
              stipulations
              projectDetailSubmission
              createdBy
              financeTypeStatus
              financeCompanyStatus
              loanDetailsStatus
              borrowDetailsStatus
              paymentDetailsStatus
              pvSystemDetailsStatus
            }
          }
          implementation {
            permissionToInterconnect {
              submissionDate
              status
              notes
              utilityApplicationNumber
              intakeDate
              intakeStatus
              intakeNotes
            }
            permitting {
              authorityHavingJurisdiction
              submissionDate
              submissionStatus
              applicationNumber
              fee
              paymentMethod
              paymentDate
              paymentStatus
              intakeDate
              intakeStatus
              printed
              notes
            }
            financing {
              submissionDate
              status
              intakeDate
              intakeStatus
              notes
            }
            ahjInspection {
              submissionDate
              status
              intakeDate
              intakeStatus
              notes
            }
            utilityPto {
              submissionDate
              status
              netMeterInstallationDate
              netMeterInstallationStatus
              ptoDate
              ptoStatus
              notes
            }
            systemActivation {
              mainBreakerPowerDate
              mainBreakerPowerStatus
              systemActivationDate
              systemActivationStatus
              systemOwnerConnectionType
              enlightenInvitationDate
              enlightenInvitationStatus
              enlightenAccessDate
              enlightenAccessStatus
              notes
            }
          }
        }
        phaseGroup {
          id
          groupName
          isSequential
          displayName
          tenantId
          orderNo
          groupCompletionPercentage
          status
        }
      }
    }
  }
`;

export const GET_BANK_LOANS = gql`
  query GetBankLoans($projectId: ID!, $proposalId: ID) {
    projectManagement {
      project(id: $projectId) {
        proposal(proposalId: $proposalId) {
          id
          proposalType
          cost
          systemSize
          finance {
            apr
            name
            paymentFactor1
            term
            offerId
          }
          product {
            adder
            cost
            description
            name
          }
          contract {
            agreementStatus
            amount
            coCustomerFirstName
            coCustomerLastName
            proposalId
          }
          bankingLoans {
            ... on ProjectManagementGoodLeapLoansOverview {
              connectedParties {
                name
                organizationId
                organizationTypes
              }
              loans {
                availableActions {
                  name
                  type
                }
                loanDetails {
                  id
                  createdAt
                  referenceNumber
                  status
                  offerId
                  installationCategoryId
                }
                timelineSections {
                  title
                  key
                  status
                  dateComplete
                  items
                  milestone
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECTS_MENTION = gql`
  query GetProjectMention {
    projectManagement {
      projects {
        id
      }
    }
  }
`;

export const GET_PROJECT_CONSUMPTION_PROFILE = gql`
  query GetProjectConsumptionByID($projectId: ID!) {
    projectManagement {
      project(id: $projectId) {
        designPlatform {
          consumptionProfile {
            utility
            monthlyEnergy
            monthlyBill
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_NOTE_NOTIFICATIONS = gql`
  query GetProjectNoteNotification($projectId: ID!) {
    projectManagement {
      project(id: $projectId) {
        activityFeed {
          id
          title
          message
          readAt
          createdAt
          remarks
          type
          data {
            ... on IdentityNote {
              content
              contextId
              context
              createdAt
              createdBy {
                firstName
                lastName
              }
              mentions {
                mentionId
                mentionContext {
                  ... on IdentityUser {
                    email
                    lastName
                    firstName
                    twilio {
                      projectedAddress
                    }
                  }
                  ... on CrmLead {
                    email
                    address {
                      streetNo
                      streetName
                      city
                      state
                    }
                    name
                    contactNo
                  }
                  ... on ProjectManagementProject {
                    id
                    customer {
                      address
                      firstName
                      lastName
                      email
                      phone
                    }
                  }
                }
              }
            }
          }
          user {
            entityContextType
            entityContext {
              ... on CrmLead {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_DESIGNS = gql`
  query GetProjectDesigns($projectId: ID!) {
    projectManagement {
      project(id: $projectId) {
        designPlatform {
          design {
            id
            name
            summary {
              designId
              designRequestId
              externalProviderId
              projectId
              createdAt
              systemSizeAc
              systemSizePtc
              systemSizeStc
              billOfMaterials {
                id
                componentType
                sku
                name
                manufacturerName
                quantity
              }
              energyProduction {
                upToDate
                annual
                annualOffset
              }
            }
            asset {
              assetType
              designId
              fileName
              projectId
              url
            }
            shareLinkUrl
            externalProviderId
          }
        }
      }
    }
  }
`;
