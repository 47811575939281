import { gql } from '@apollo/client';

export const GET_AVAILABLE_TWILIO_NUMBERS = gql`
  query GetAvailableTwilioNumbers($filter: IdentityTwilioAvailableNumberPagedFilter) {
    identity {
      admin {
        twilio {
          availableNumbers(filter: $filter) {
            phoneNumber
            friendlyName
            lata
            rateCenter
            latitude
            longitude
            locality
            region
            postalCode
            isoCountry
            addressRequirements
            beta
            capabilities {
              voice
              sms
              mms
            }
          }
        }
      }
    }
  }
`;

export const GET_SENDGRID_SENDER_ACCOUNTS = gql`
  query SendGridAccounts($filter: IdentitySendGridAccountFilter, $search: IdentitySendGridAccountSearch) {
    identity {
      admin {
        twilio {
          sendGridAccounts(filter: $filter, search: $search) {
            id
            nickName
            addressLine1
            addressLine2
            city
            state
            zipCode
            country
            createdAt
            locked
            from {
              email
              name
            }
            verified {
              status
              reason
            }
            replyTo {
              email
              name
            }
          }
        }
      }
    }
  }
`;
