import { SidebarItemsType } from '../../types/sidebar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import { UserCheck } from 'react-feather';

const userPagesSection = [
  {
    href: '/crm/user-profile/user/profile',
    title: 'Profile',
    icon: AccountCircleIcon,
  },
] as SidebarItemsType[];

const adminPagesSection = [
  {
    href: '/crm/user-profile/admin/company-management',
    title: 'Company Management',
    icon: BusinessIcon,
    children: [
      {
        href: '/crm/user-profile/admin/company-management/company-details',
        title: 'Company Details',
      },
    ],
  },
  {
    href: '/crm/user-profile/admin/user-management',
    title: 'User Management',
    icon: UserCheck,
    children: [
      {
        href: '/crm/user-profile/admin/user-management/users',
        title: 'Users',
      },
      {
        href: '/crm/user-profile/admin/user-management/roles-and-permissions',
        title: 'Roles and permissions',
      },
    ],
  },
] as SidebarItemsType[];

export const navItemsUser = [
  {
    title: 'Your Preferences',
    pages: userPagesSection,
  },
];

export const navItemsAdmin = [
  {
    title: 'Admin Preferences',
    pages: adminPagesSection,
  },
];
