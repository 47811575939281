import React from 'react';
import { Tooltip } from '@mui/material';
import { SmallButton } from '../../../task/components/TaskModalFooter';
// import {ActivityType} from "../../../task/taskTypes";
import { Phone } from '@mui/icons-material';
import { ActivityType } from '../../../task/taskTypes';
import { Mail, MessageSquare } from 'react-feather';
import { CrmUser } from '../../../../types/crm/user';

interface ContactLeadModalFooterProps {
  handleActivitySelection(activity: ActivityType): void;

  selectedActivity: ActivityType;
  currentUser?: CrmUser;
}

const ContactLeadModalFooter = (props: ContactLeadModalFooterProps) => {
  const { handleActivitySelection, selectedActivity, currentUser } = props;

  const permissions = currentUser?.permissions || [];
  // Check if user has voice, sms and email permissions
  const hasVoicePermissions = permissions.some(permission => permission.name === 'feature:twilio:voice:outbound');
  const hasSmsPermissions = permissions.some(permission => permission.name === 'feature:twilio:messaging');
  const hasEmailPermissions = permissions.some(permission => permission.name === 'feature:twilio:sendgrid:email');

  const handleClick = (action: ActivityType) => {
    return () => handleActivitySelection(action);
  };

  const handleActionState = (_action: ActivityType) => {
    return false;
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        {hasVoicePermissions && (
          <Tooltip title="Phone">
            <span>
              <SmallButton
                disabled={handleActionState(ActivityType.VOICE)}
                onClick={handleClick(ActivityType.VOICE)}
                size="small"
              >
                <Phone
                  style={{
                    color: selectedActivity === ActivityType.VOICE ? '#428ADF' : '#6F6F6F',
                    width: 20,
                    height: 20,
                  }}
                />
              </SmallButton>
            </span>
          </Tooltip>
        )}
        {hasEmailPermissions && (
          <Tooltip title="Email">
            <span>
              <SmallButton
                disabled={handleActionState(ActivityType.EMAIL)}
                onClick={handleClick(ActivityType.EMAIL)}
                size="small"
              >
                <Mail
                  style={{
                    color: selectedActivity === ActivityType.EMAIL ? '#428ADF' : '#6F6F6F',
                    width: 20,
                    height: 20,
                  }}
                />
              </SmallButton>
            </span>
          </Tooltip>
        )}
        {hasSmsPermissions && (
          <Tooltip title="SMS">
            <span>
              <SmallButton
                disabled={handleActionState(ActivityType.SMS)}
                onClick={handleClick(ActivityType.SMS)}
                size="small"
              >
                <MessageSquare
                  style={{
                    color: selectedActivity === ActivityType.SMS ? '#428ADF' : '#6F6F6F',
                    width: 20,
                    height: 20,
                  }}
                />
              </SmallButton>
            </span>
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
};

export default ContactLeadModalFooter;
