import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentityEmailSendersAccount } from '../../../custom/types/identity/admin/twilio/sendGrid';

interface EmailSendersState {
  data: IdentityEmailSendersAccount[];
  isDataLoaded: boolean;
}

const initialState: EmailSendersState = {
  data: [],
  isDataLoaded: false,
};

export const emailSendersSlice = createSlice({
  name: 'emailSenders',
  initialState,
  reducers: {
    setEmailSendersData(state, action: PayloadAction<IdentityEmailSendersAccount[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
  },
});

export const { setEmailSendersData, setIsDataLoaded } = emailSendersSlice.actions;
