import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmUser } from '../../../custom/types/crm/user';

interface UsersState {
  data: CrmUser[];
  isDataLoaded: boolean;
  totalUsers: number;
}

const initialState: UsersState = {
  data: [],
  isDataLoaded: false,
  totalUsers: 0,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersData(state, action: PayloadAction<CrmUser[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalUsers(state, action: PayloadAction<number>) {
      state.totalUsers = action.payload;
    },
  },
});

export const { setUsersData, setIsDataLoaded, setTotalUsers } = usersSlice.actions;
