import React, { Global, css } from '@emotion/react';
import { amplifyStyles } from '../custom/styles/amplifyCSS';
import { draftJSMentionStyle } from '../custom/styles/draftJSMentionStyle';

/**
 *
 * @param props
 */
const GlobalStyle = (props: any) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }

      iframe#webpack-dev-server-client-overlay {
        display: none;
      }

      .pac-container {
        z-index: 2000;
      }

      ::-webkit-media-controls-panel {
        background-color: #2196f3;
        color: #ffffff;
      }

      /* Set the color of the play button */
      ::-webkit-media-controls-play-button,
      ::-webkit-media-controls-pause-button {
        filter: invert(100%);
      }

      /* Set the color of the volume control */
      ::-webkit-media-controls-volume-slider-container,
      ::-webkit-media-controls-volume-slider,
      ::-webkit-media-controls-mute-button {
        filter: invert(100%);
      }

      /* Set the color and remove the shadow of the current time and remaining time */
      ::-webkit-media-controls-current-time-display,
      ::-webkit-media-controls-time-remaining-display {
        color: white;
        text-shadow: none; /* Remove the shadow */
      }

      /* Set the color of the progress bar */
      ::-webkit-media-controls-timeline,
      ::-webkit-media-controls-seek-back-button,
      ::-webkit-media-controls-seek-forward-button {
        filter: invert(100%);
      }

      /* Set the color of internal overflow button (three dots) */
      audio::-internal-media-controls-overflow-button {
        filter: invert(100%);
      }

      /* Set the color of the download button */
      ::-webkit-media-controls-download-button,
      ::-webkit-media-controls-fullscreen-button,
      ::-webkit-media-controls-rewind-button,
      ::-webkit-media-controls-return-to-realtime-button {
        filter: invert(100%);
      }

      ::-webkit-media-controls-current-time-display,
      ::-webkit-media-controls-time-remaining-display {
        color: black;
      }

      ::-webkit-media-controls-panel {
        background-color: white;
      }

      ::-webkit-media-controls-play-button,
      ::-webkit-media-controls-pause-button {
        filter: none;
      }

      ::-webkit-media-controls-volume-slider-container,
      ::-webkit-media-controls-volume-slider,
      ::-webkit-media-controls-mute-button {
        filter: none;
      }

      ::-webkit-media-controls-timeline,
      ::-webkit-media-controls-seek-back-button,
      ::-webkit-media-controls-seek-forward-button {
        filter: none;
      }

      ${amplifyStyles}
      ${draftJSMentionStyle}
    `}
  />
);

export default GlobalStyle;
