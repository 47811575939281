import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Card as MuiCard,
  CardHeader,
  Grid,
  Paper,
  Skeleton,
  TablePagination,
  TableRow,
  TableRowProps,
  useMediaQuery,
} from '@mui/material';
import { spacing } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';

const Card = styled(MuiCard)(spacing);

// Table Styles (keep these styles for table view)
const TableWrapper = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableHead = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTableRow = styled(TableRow)<
  TableRowProps & { component?: React.ElementType; to?: string; highlight?: boolean; disabled?: boolean }
>`
  display: flex;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  &:hover {
    background-color: ${props => (props.disabled ? 'inherit' : 'rgba(0, 0, 0, 0.04)')};
  }

  background-color: ${props => (props.highlight ? 'rgba(55, 111, 208, 0.08)' : 'inherit')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const StyledTableCell = styled.div<{
  flex?: number;
  align?: 'right' | 'left' | 'center';
  width?: string;
  compact?: boolean;
}>`
  flex: ${props => (props.width ? '0 0 auto' : props.flex || 1)};
  text-align: ${props => props.align || 'left'};
  padding: ${props => (props.compact ? '10px' : '16px')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${props => props.width || 'auto'};
  min-width: ${props => props.width || 'auto'};

  &:last-child {
    border-right: none;
  }
`;

export interface NucleusTableColumn {
  field: string;
  headerName: string;
  align?: 'right' | 'left' | 'center';
  headerAlign?: 'right' | 'left' | 'center';
  width?: string;
  editable?: boolean;
  flex?: number;
  renderCell?: (value: any, row: DataRow) => React.ReactNode;
}

export interface DataRow {
  [key: string]: any;

  url?: string;
  disabled?: boolean;
}

interface NucleusTableProps {
  title?: string;
  columns: NucleusTableColumn[];
  data: DataRow[];
  loading: boolean;
  totalCount?: number;
  onPageChange?: (page: number, rowsPerPage: number) => void;
  paginationEnabled?: boolean;
  emptyCardRender?: () => React.ReactNode; // New prop for custom card rendering
  cardRender?: (row: DataRow) => React.ReactNode; // New prop for custom card rendering
  enableCardView?: boolean; // Flag to control card view
  forceCardView?: boolean; // Flag to force card view
  compact?: boolean; // Flag to control compact view
  defaultRowsPerPage?: 25 | 50 | 100;
}

const NucleusTable: React.FC<NucleusTableProps> = ({
  title,
  columns,
  data,
  totalCount,
  onPageChange,
  paginationEnabled = false,
  cardRender,
  emptyCardRender,
  loading,
  enableCardView = false, // Default to false, rendering table
  forceCardView = false,
  compact = false,
  defaultRowsPerPage,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState((defaultRowsPerPage as number) ?? 100);
  const location = useLocation();
  const isSmallScreen = useMediaQuery('(max-width: 768px)'); // Detect small screen

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);

    // Call the onPageChange callback if it is provided
    if (onPageChange) {
      onPageChange(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    // Call the onPageChange callback if it is provided
    if (onPageChange) {
      onPageChange(0, newRowsPerPage);
    }
  };

  if ((enableCardView && isSmallScreen) || forceCardView) {
    // If cards are enabled on small screen, render using the custom card render passed from the parent
    return (
      <>
        {loading && [...Array(5)].map(() => (emptyCardRender ? emptyCardRender() : null))}
        {!loading && data.map(row => <React.Fragment>{cardRender ? cardRender(row) : null}</React.Fragment>)}
        {!loading && paginationEnabled && totalCount && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card mb={6}>
          {title && <CardHeader title={title} />}
          <Paper>
            <TableWrapper>
              <StyledTable role="row">
                <StyledTableHead>
                  {columns.map(column => (
                    <StyledTableCell
                      key={column.field}
                      flex={column.flex}
                      align={column.headerAlign}
                      width={column.width}
                    >
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </StyledTableHead>

                {loading && (
                  <StyledTableBody>
                    {[...Array(5)].map((_, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <StyledTableCell
                            key={colIndex}
                            flex={column.flex || 1}
                            align={column.headerAlign || 'center'}
                            width={column.width || '100%'}
                            style={{ padding: '8px' }}
                          >
                            <Skeleton height={20} width="100%" />
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </StyledTableBody>
                )}

                {!loading && (
                  <StyledTableBody>
                    {data.map((row, rowIndex) => {
                      const pathParts = location.pathname.split('/');
                      const lastPart = pathParts[pathParts.length - 1];
                      const isCurrentUrl = row.url && lastPart === row.url;

                      return row.url ? (
                        <StyledTableRow
                          component={Link}
                          to={row.url}
                          highlight={isCurrentUrl as boolean}
                          key={rowIndex}
                          role="rowgroup"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {columns.map(column => (
                            <StyledTableCell
                              key={column.field}
                              flex={column.flex}
                              align={column.align}
                              width={column.width}
                            >
                              {column.renderCell ? column.renderCell(row[column.field], row) : row[column.field]}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      ) : (
                        <StyledTableRow key={rowIndex} role="rowgroup">
                          {columns.map(column => (
                            <StyledTableCell
                              key={column.field}
                              flex={column.flex}
                              align={column.align}
                              width={column.width}
                              compact={compact}
                            >
                              {column.renderCell ? column.renderCell(row[column.field], row) : row[column.field]}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      );
                    })}
                  </StyledTableBody>
                )}
              </StyledTable>
            </TableWrapper>
            {!loading && paginationEnabled && totalCount && totalCount > 0 && (
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NucleusTable;
