// import { InterestTypeProps } from './interestType';
// import { UserProps } from './user';

import { CrmAddress } from './address';
import { CrmAppointment } from './appointment';
import { CrmInterestType } from './interestType';
import { CrmLeadForm } from './leadForm';
import { CrmRoofType } from './roofType';
import { CrmUser } from './user';
import { CrmUserConversation } from '../conversation';
import { ProjectType } from '../project';
import { CrmTaskSchedule } from './taskSchedule/taskSchedule';
import { CrmNotification } from './notification';

export type CrmLeads = {
  crm: {
    leads: CrmLead[];
  };
};

export type CrmLeadsPaged = {
  crm: {
    leadsPaged: {
      leads: CrmLead[];
      totalCount: number;
    };
  };
};

export type CrmLead = {
  id: number;
  name: string;
  email: string;
  contactNo: string;
  roofTypeId: number;
  roofType: CrmRoofType;
  zipCode: string;
  hasTaskSequence: boolean;
  hasTaskScaffolded: boolean;
  interestType: CrmInterestType;
  interestTypeId: number;
  leadFormId: number;
  leadForm: CrmLeadForm;
  leadGuide: [CrmLeadGuide];
  assignedToId: number;
  assignedToUser: CrmUser;
  assignedById: number;
  assignedByUser: CrmUser;
  createdAt: string;
  closer: CrmUser;
  status: CrmLeadStatus;
  isContacted: boolean;
  addressId: number;
  address: CrmAddress;
  appointments: CrmAppointment[];
  isCallableByAssignedUser: boolean;
  reassignment: CrmReassignment;
  conversation?: CrmUserConversation[];
  taskSchedules?: CrmTaskSchedule[];
  project?: ProjectType[];
  callRecordings: CrmRecording[];
  activityFeed: CrmNotification[];
  __typename?: string;
};

export type CrmReassignment = {
  id: number;
  fromUserId: string;
  toUserId: string;
  status: string;
  remarks: string;
  createdAt: string;
};

export type CrmLeadGuide = {
  id: number;
  title: string;
  contentBody: string;
  interestTypeId: number;
  interestType: CrmInterestType;
  createdAt: string;
};

export type CrmCreateLeadResponseType = {
  crm: {
    lead: {
      createLead: {
        success: boolean;
        leadId: string;
      };
    };
  };
};

export type CrmRecording = {
  dialer: string;
  duration: string;
  endTime: string;
  mediaUrl: string;
  receiver: string;
  summary: string;
  createdAt: string;
};

export type CrmCreateLead = {
  crm: {
    lead: {
      createLead: {
        lead: CrmLead;
        success: boolean;
        leadId: string;
      };
    };
  };
};

export type CrmCreateLeadWithMultipleInterests = {
  crm: {
    lead: {
      createLeadWithMultipleInterests: {
        lead: CrmLead;
        success: boolean;
        leadId: string;
      };
    };
  };
};

export type CrmLeadNotes = {
  crm: {
    leadsPaged: {
      leads: CrmLead[];
    };
  };
};

export enum CrmInterestTypeEnums {
  ROOF = 'ROOF',
  SOLAR = 'SOLAR',
  HVAC = 'HVAC',
}

export enum CrmLeadStatus {
  UNCLAIMED = 'UNCLAIMED',
  CLAIMED = 'CLAIMED',
}
