import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrmUserCallActivity } from '../custom/types/crm/callActivity';

interface CallActivityState {
  data: CrmUserCallActivity[];
  isDataLoaded: boolean;
  totalCalls: number;
}

const initialState: CallActivityState = {
  data: [],
  isDataLoaded: false,
  totalCalls: 0,
};

export const callActivitySlice = createSlice({
  name: 'callActivity',
  initialState,
  reducers: {
    setCallActivityData(state, action: PayloadAction<CrmUserCallActivity[]>) {
      state.data = action.payload;
    },
    setIsDataLoaded(state, action: PayloadAction<boolean>) {
      state.isDataLoaded = action.payload;
    },
    setTotalCalls(state, action: PayloadAction<number>) {
      state.totalCalls = action.payload;
    },
  },
});

export const { setCallActivityData, setIsDataLoaded, setTotalCalls } = callActivitySlice.actions;
