export enum PhoneNumberCountry {
  US = 'US',
  PH = 'PH',
  Unknown = 'Unknown',
}

export interface PhoneNumberFormmaterProps {
  phoneNumber: string;
}

const identifyPhoneNumberCountry = (phoneNumber: string): PhoneNumberCountry => {
  // Remove non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check for PH format (starts with +63 or 09)
  if (cleaned.startsWith('63') || cleaned.startsWith('09')) {
    return PhoneNumberCountry.PH;
  }

  // Check for US format (starts with +1 or all digits and optional hyphens)
  if (cleaned.startsWith('1') || /^[0-9-]+$/.test(cleaned)) {
    return PhoneNumberCountry.US;
  }

  return PhoneNumberCountry.Unknown;
};

export const PhoneNumberFormmater = (props: PhoneNumberFormmaterProps) => {
  const { phoneNumber } = props;
  const phoneNumberFormat = identifyPhoneNumberCountry(phoneNumber);

  const FormatUSNumber = (usPhone: string) => {
    // Remove non-digit characters
    const cleaned = usPhone.replace(/\D/g, '');

    // Handle various formats like +1, local, etc.
    let formatted;
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
      // +1 format, US number with country code
      formatted = `+${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    } else if (cleaned.length === 10) {
      // US local number without country code
      formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else {
      // Fallback to the original format if not matched
      formatted = usPhone;
    }

    return formatted;
  };

  const FormatPHNumber = (phPhone: string) => {
    // Format as +63 000 000 0000
    const match = phPhone.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `+63 ${match[2]} ${match[3]} ${match[4]}`;
    }

    return phPhone;
  };

  switch (phoneNumberFormat) {
    case PhoneNumberCountry.US:
      return FormatUSNumber(phoneNumber);
    case PhoneNumberCountry.PH:
      return FormatPHNumber(phoneNumber);
    default:
      throw new Error(`Unsupported date format: ${phoneNumber}`);
  }
};
