import { CrmAddress } from './crm/address';
import { CustomerType } from './project';

const formatAddress = (components: (string | undefined | null)[]) => {
  return components.filter(Boolean).join(', ') || '';
};

const AddressFormatter = (props: { address: CrmAddress }) => {
  const { streetNo, streetName, city, state, zipCode } = props.address || {};
  const address = `${streetNo} ${formatAddress([streetName, city, state])} ${zipCode}`;

  return address;
};

const ProjectAddressFormatter = (props: { address: CustomerType }) => {
  const { block, crossStreet, lot, city, state, zip } = props.address || {};
  const formattedCity = city ? `${city} City` : '';
  const address = formatAddress([block, crossStreet, lot, formattedCity, state, zip]);

  return address;
};

export { AddressFormatter, ProjectAddressFormatter };
