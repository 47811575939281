export enum CrmGlobalSearchResultType {
  'Contact' = 'CONTACT',
  'Project' = 'PROJECT',
  'Lead' = 'LEAD',
}

export type GlobalSearchResults = {
  crm: {
    globalSearch: {
      type: CrmGlobalSearchResultType;
      id: string;
      title: string;
      subTitle: string;
      url: string;
    }[];
  };
};
