import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  IconButton,
  Link,
  TextFieldProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { spacing, SpacingProps, useTheme } from '@mui/system';
import * as Yup from 'yup';
import { Field, Formik, FormikProps, FormikValues } from 'formik';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useMutation } from '@apollo/client';
import { FormikTextField } from '../components/formElements/FormikTextField';
import LeadMap, { GoogleMapPositionTypes, LeadMapProps } from './task/components/LeadMap';
import FormikGoogleMapsSearchBox from '../components/formElements/FormikGoogleMapsSearchBox';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { AddressResult, getMapAddress } from '../types/map';
import NucleusLoader from '../NucleusLoader';
import PhoneNumberInput from '../components/formElements/FormikPhoneNumberInput';
import { matchIsValidTel } from 'mui-tel-input';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {
  CREATE_TENANT,
  IdentityTenantDefaultUser,
  IdentityTenantMutationResult,
  IdentityTenantType,
} from '../types/identity/tenant/tenant';
import FormikAutoComplete from '../components/formElements/FormikAutoComplete';

const libraries: Libraries | undefined = ['places'];

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Grid = styled(MuiGrid)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

type CreateNewTenantFormFormikFieldTypes = {
  name: string;
  companyIdentifier: string;
  phone: string;
  registrationNumber: string;
  defaultUser: IdentityTenantDefaultUser | null;
  tenantType: IdentityTenantType | null;
  submit: string;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

function PageHeader() {
  return (
    <div>
      <Helmet title="Create Lead" />
      <Typography variant="h3" gutterBottom display="inline">
        New Tenant
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/crm/leads">
          Tenant
        </Link>
        <Typography>New Tenant</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}></Grid>
      </Grid>
    </div>
  );
}

function TenantForm() {
  const { toggleState, setMessage } = useSnackbar();
  const [createTenant] = useMutation<IdentityTenantMutationResult>(CREATE_TENANT);
  const [mapMarker, setMapMarker] = useState<google.maps.Marker | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const initialPosition = {
    lat: 12.9716,
    lng: 77.5946,
  };
  const [position, setPosition] = useState<GoogleMapPositionTypes>(initialPosition);
  const formikRef = useRef<FormikProps<CreateNewTenantFormFormikFieldTypes>>(null);
  const [address, setAddress] = useState<AddressResult>({} as AddressResult);
  // @ts-ignore
  const [defaultUser, setDefaultUser] = useState<IdentityTenantDefaultUser>({} as IdentityTenantDefaultUser);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const AlertStyle = {
    width: isSmallScreen ? '100%' : '95%',
    fontSize: 12,
    '& .MuiAlert-message': {
      width: '100%',
      textAlign: 'center',
    },
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
    '& .MuiAlert-action': {
      alignItems: 'center',
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCmxw8d96uQwl80ba-ka1Vdj9H9JHddwL0',
    libraries: libraries,
  });

  const initialValues: CreateNewTenantFormFormikFieldTypes = {
    name: '',
    companyIdentifier: '',
    phone: '',
    registrationNumber: '',
    defaultUser: {
      email: '',
      firstName: '',
      lastName: '',
    },
    tenantType: null,
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    companyIdentifier: Yup.string().required('Company Identifier is required'),
    phone: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Phone number is invalid', value =>
        matchIsValidTel(value ?? '', { onlyCountries: ['US', 'PH'] }),
      ),
    registrationNumber: Yup.string().required('Registration Number is required'),
    defaultUser: Yup.object()
      .shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
      })
      .required('Default User details are required'),
    tenantType: Yup.object().required('Tenant type is required'),
  });

  const handleOnTenantCreationSuccess = async () => {
    if (formikRef.current) {
      setShowAlert(true);
      formikRef.current.setSubmitting(false);
      toggleState();
      formikRef.current.resetForm();
      formikRef.current.setStatus({ sent: true });
      setMessage('Tenant added successfully!');

      enqueueSnackbar('Tenant created', {
        variant: 'success',
        persist: false,
        action: key => (
          <IconButton title="Close sucess" onClick={() => closeSnackbar(key)}>
            <CloseIcon color="disabled" />
          </IconButton>
        ),
      });

      navigate('/crm/tenant');
    }
  };

  const handleSubmitForm = async (_values: FormikValues, { setErrors, setStatus, setSubmitting }: any) => {
    setErrors({ submit: '' });

    let isSuccess = false;
    let tenantCreationResult = undefined;

    try {
      tenantCreationResult = await createTenant({
        variables: {
          input: {
            name: _values.name,
            companyIdentifier: _values.companyIdentifier,
            phone: _values.phone,
            registrationNumber: _values.registrationNumber,
            address: {
              streetNo: address.streetNumber,
              streetName: address.streetName,
              city: address.city,
              state: address.state,
              zipCode: address.zipCode,
              country: address.country,
              lat: address.latitude.toString(),
              lng: address.longitude.toString(),
            },
            defaultUser: {
              email: _values.defaultUser.email,
              firstName: _values.defaultUser.firstName,
              lastName: _values.defaultUser.lastName,
            },
            tenantType: 'ASSIGNED_SALES_DEALER',
          },
        },
      });

      isSuccess = tenantCreationResult?.data?.success ? true : false;

      if (isSuccess) {
        handleOnTenantCreationSuccess();
      }

      return;
    } catch (error) {
      isSuccess = tenantCreationResult?.data?.success ? true : false;

      setShowAlert(true);
      setSubmitting(false);
      toggleState();

      if (isSuccess) {
        handleOnTenantCreationSuccess();
      }

      if (error instanceof Error) {
        setStatus({ sent: false });
        setMessage(error.message);
        setErrors({ submit: error.message });
      }

      return;
    }
  };

  const handleOnMapMarkerOnChange = (googleMapMarkerInit: google.maps.Marker | null) => {
    setMapMarker(googleMapMarkerInit);
  };

  const onSearchBoxLoad = (googleMapsSearchBoxRef: google.maps.places.SearchBox) => {
    setSearchBox(googleMapsSearchBoxRef);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleOnMapOnChange = (googleMapInit: google.maps.Map | null) => {
    setMap(googleMapInit);
  };

  const changeMapLocation = (newPosition: GoogleMapPositionTypes) => {
    setPosition(newPosition);
    if (map) {
      map.panTo(newPosition);
    }
  };

  const updateAddress = (location: { lat: number; lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        const getAddress: AddressResult = getMapAddress(results[0]);
        setAddress(getAddress);

        const formattedAddress = `${getAddress.streetNumber} ${getAddress.streetName} ${getAddress.city} ${getAddress.state} ${getAddress.zipCode} ${getAddress.country}`;
        formikRef.current?.setFieldValue('address', formattedAddress.trim());
      }
    });
  };

  const handlePlaceChanged = () => {
    const places = searchBox?.getPlaces();
    if (places?.[0]?.geometry?.location) {
      const nextCenter = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      };
      updateAddress(nextCenter);
      changeMapLocation(nextCenter);
    }
  };

  const handleDragEnd = async (e: google.maps.MapMouseEvent) => {
    if (mapMarker && e && e.latLng) {
      updateAddress({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  };

  const leadMapProps: LeadMapProps = {
    position: position,
    useMarker: true,
    mapContainerStyle: {
      height: '480px',
      width: '100%',
    },
    handleOnMapOnChange: handleOnMapOnChange,
    handleOnMapMarkerOnChange: handleOnMapMarkerOnChange,
    handleDragEnd: handleDragEnd,
    options: {
      streetViewControl: true,
    },
    zoom: 20,
    showAddressLabel: true,
  };

  if (loadError || !isLoaded) {
    return (
      <React.Fragment>
        <NucleusLoader />
      </React.Fragment>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      innerRef={formikRef}
    >
      {({ errors, status, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Tenant Information
            </Typography>
            <Typography variant="body2" gutterBottom>
              Default User Details
            </Typography>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="defaultUser.email"
                  label="Email"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="defaultUser.firstName"
                  label="First Name"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="defaultUser.lastName"
                  label="Last Name"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" gutterBottom>
                  Tenant Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="name"
                  label="Name"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="companyIdentifier"
                  label="Company Identifier"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="phone" component={PhoneNumberInput} onlyCountries={['US', 'PH']} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="tenantType"
                  component={FormikAutoComplete}
                  label="Tenant Type"
                  options={[{ label: 'ASSIGNED SALES DEALER', value: IdentityTenantType.ASSIGNED_SALES_DEALER }]}
                  touched={touched}
                  errors={errors}
                  defaultValue={{
                    label: 'ASSIGNED SALES DEALER',
                    value: IdentityTenantType.ASSIGNED_SALES_DEALER,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="registrationNumber"
                  label="Registration Number"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item container xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="address"
                    label=""
                    component={FormikGoogleMapsSearchBox}
                    touched={touched}
                    errors={errors}
                    values={values}
                    textFieldProps={
                      {
                        fullWidth: true,
                        margin: 'dense',
                        variant: 'outlined',
                        autoFocus: false,
                        InputProps: undefined,
                        sx: undefined,
                        placeholder: 'Tenant Address',
                      } as TextFieldProps
                    }
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onKeyDown={handleKeyDown}
                    handlePlaceChanged={handlePlaceChanged}
                    onSearchBoxLoad={onSearchBoxLoad}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container flexDirection={'column'} xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Address
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Indicate details for tenant address.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LeadMap {...leadMapProps} />
                </Grid>
                <Grid item container xs={12} rowSpacing={2}>
                  <Grid item container justifyContent={'center'} alignItems={'center'} xs={12} sm={'auto'}>
                    <Button type="submit" variant="contained" color="primary" fullWidth={isSmallScreen}>
                      {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <NucleusLoader type={'circular'} sx={{ color: 'white', marginRight: 2 }} size="1rem" />{' '}
                          Creating Tenant
                        </Box>
                      ) : (
                        'Create Tenant'
                      )}
                    </Button>
                  </Grid>
                  <Grid item container justifyContent={'center'} xs={12} sm>
                    {showAlert && status && status.sent && (
                      <Alert
                        severity="success"
                        sx={AlertStyle}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        Tenant successfully created
                      </Alert>
                    )}
                    {showAlert && errors && errors.submit && (
                      <Alert
                        severity="error"
                        sx={AlertStyle}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        {errors.submit}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      )}
    </Formik>
  );
}

function NewTenant() {
  return (
    <React.Fragment>
      <PageHeader />
      <TenantForm />
    </React.Fragment>
  );
}

export default NewTenant;
