import { gql } from '@apollo/client';

export type IdentityCreateTenant = {
  name: string;
  companyIdentifier: string;
  address: IdentityTenantAddress;
  phone: string;
  registrationNumber: string;
  defaultUser: IdentityTenantDefaultUser;
  tenantType: IdentityTenantType;
};

export type IdentityTenantAddress = {
  streetNo: string;
  streetName: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  lat: string;
  lng: string;
};

export type IdentityTenantDefaultUser = {
  email: string;
  firstName: string;
  lastName: string;
};

export type IdentityTenantMutationResult = {
  success: boolean;
};

export enum IdentityTenantType {
  INSTALLER = 'INSTALLER',
  ASSIGNED_SALES_DEALER = 'ASSIGNED_SALES_DEALER',
}

export const CREATE_TENANT = gql`
  mutation CreateTenant($input: IdentityCreateTenantInput!) {
    identity {
      admin {
        tenant {
          createTenant(input: $input) {
            tenantId
            success
          }
        }
      }
    }
  }
`;
